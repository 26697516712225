import React, { Component } from 'react';
import { Row,Col } from 'antd';

import './style/common.less';
import './style/loading.less';

import Header from './components/Header/index'
import NavLeft from './components/NavLeft/index'



export default  class Admin extends Component {


  render() {
    return (
      <Row className="container">
          <Col span={4} className="nav-left">
            <NavLeft></NavLeft>
          </Col>
          <Col span={20} className="main">
            <Header className="top"></Header>
            <Row className="down">
              {this.props.children}
            </Row>
          </Col>
          
        </Row>
    );
  }
}


