import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import { Table, Divider, Button, Card, message, Popconfirm, Form, Input } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import { set_agent_role_id} from '../../../redux/action'
const FormItem = Form.Item;
class User_list extends React.Component {
  state = {
    data: [],
    user_amount: 0
  }
  componentWillMount() {
    this.init_data();
  }
  // 初始化数据
  init_data(mobile = '') {
    Axios.ajax({
      url: '/agent_role/lst.php',
      data: {
        token: this.props.userinfo.token
      }
    }).
      then((res) => {
        this.setState({
          data: res.data,
  
        })
      })
  }
  // 编辑角色
  edit=(data)=>{
    const { dispatch } = this.props;
    dispatch(set_agent_role_id(data.id));
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    }, {
      title: '角色名称',
      dataIndex: 'title',
      align: 'center',
    }, {
      title: '直接推广额外奖励比例(%)',
      align: 'center',
      dataIndex: 'extra_bonus_rate_one',
    }, {
      title: '间接推广额外奖励比例(%)',
      align: 'center',
      dataIndex: 'extra_bonus_rate_two',
    }, {
      title: '其他推广额外奖励比例(%)',
      align: 'center',
      dataIndex: 'extra_bonus_rate_other',
    }, {
      title: '创建时间',
      align: 'center',
      dataIndex: 'create_time',
    },{
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <div className="min-width-controll">
          <NavLink to="/agent_role/edit" ><Button type="primary" onClick={this.edit.bind(this, record)} >修改</Button></NavLink>
          
          {/* <Divider type="vertical" />
          <Popconfirm title="是否删除该角色？" okText="是" cancelText="否" onConfirm={this.delete.bind(this, record, index)}>
            <Button type="primary">删除</Button>
          </Popconfirm> */}

        </div>
      )}
    ];
    return (
      <Card >
        
        <NavLink to="/agent_role/add" className="teacher-header-wrap">
          <Button type="primary"  >新增角色</Button>
        </NavLink>
        <Table columns={columns} dataSource={this.state.data} className="table-view" />
      </Card>

    );
  }
}

User_list = Form.create()(User_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(User_list)