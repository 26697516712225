import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import {set_course_id} from '../../../redux/action'
import { Table, Divider, Button, Card, message, Popconfirm, Select, Form, Input,TreeSelect } from 'antd';
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { Modal } from 'antd'
const FormItem = Form.Item;
const Option = Select.Option;
class Course_list extends React.Component {
  state = {
    goto_course_add: false,
    goto_another_page: false,
    goto_another_page_path: '',
    course_add_id:undefined,
    course_cate: [],
    course_ad:[],
    modal_visible:false,
    data: []
  }
  // 新增专栏
  add = (e) => {
    this.setState({ goto_course_add: true });
  }
  // 编辑
  edit(index) {

    const { dispatch } = this.props;
    dispatch(set_course_id(index.id));
    this.setState({
      goto_another_page: true,
      goto_another_page_path: "/course/edit" 
    })
  }
  statistic(record) {

    const { dispatch } = this.props;
    dispatch(set_course_id(record.id));
    this.setState({
      goto_another_page: true,
      goto_another_page_path: "/course/statistic" 
    })
  }


  // 新增内容
  add_video(index){
  //  console.log(index.id)
    const { dispatch } = this.props;
    dispatch(set_course_id(index.id));
    this.setState({
      goto_another_page: true,
      goto_another_page_path: "/video/list" 
    })
  }
  // 删除
  delete(index, cur) {

    Axios.ajax({
      url: '/course/delete.php',
      data: {
        token: this.props.userinfo.token,
        course_id: index.id
      }
    }).
      then((res) => {
        message.success("移除成功");
        this.init_data();
      })

  }

  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();

          this.init_data(data);


  }
  componentWillMount() {

    this.init_data();
    // 初始化专栏分类
    this.init_course_cate();
  }
  // 初始化专栏分类
  init_course_cate(){
    let data={};
    data.token=this.props.userinfo.token;
    Axios.ajax({
      url: '/course_cate/list.php',
      data: data
    }).
      then((res) => {
         let course_cate_arr=[];
         let tmp={"id":"0",'title':"全部分类"};
         course_cate_arr.push(tmp);
         for(let i=0;i<res.data.length;i++){
            let tmp2={};
             tmp2.id=res.data[i].id;
             tmp2.title=res.data[i].title;
            course_cate_arr.push(tmp2);  
         }
        this.setState({
          course_cate: course_cate_arr
        })
      })

  }
  // 初始化数据
  init_data(data={}) {
    data.token=this.props.userinfo.token;
    Axios.ajax({
      url: '/course/list.php',
      data: data
    }).
      then((res) => {
        this.setState({
          data: res.data
        })
      })
  }
  change_ad=(value)=>{
    let that=this
    that.setState({
      course_add_id:value
    })

  }
  modal_hidden=()=>{
    let that=this
    that.setState({
      current_course_id:undefined,
      modal_visible:false,
      course_add_id:undefined
    })

  }
  modal_show=(course_id)=>{
    let that=this
    let data={}
    data.token=this.props.userinfo.token;
    data.course_id=course_id
    Axios.ajax({
      url: '/course_ad/list.php',
      data: data
    }).
      then((res) => {
        let course_add_id=undefined;
        if(res.data.key!=''){
          course_add_id=res.data.key
        }
        that.setState({
          current_course_id:course_id,
          modal_visible:true,
          course_ad:res.data.list,
          course_add_id:course_add_id
        })
      })
  }
  submit_ad=()=>{
    let that=this
    let data={}
    console.log(that.state.current_course_id)
    if(that.state.current_course_id==undefined){
      message.info("系统异常")
      return false;
    }
    console.log(that.state.course_add_id)
    if(that.state.course_add_id==undefined){
      that.state.course_add_id=""
      return false;
    }
    
    data.token=this.props.userinfo.token;
    data.course_id=that.state.current_course_id
    data.course_add_id=that.state.course_add_id.join(",")
    Axios.ajax({
      url: '/course_ad/edit.php',
      data: data
    }).
      then((res) => {
        message.info("操作成功")
        that.modal_hidden();
      })


  }

  render() {
    let that=this
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: '编号',
        dataIndex: 'id',
        align: 'center',
      },
      {
        title: '专栏名称',
        dataIndex: 'title',
        align: 'center',
      },  {
        title: '专栏封面',
        dataIndex: 'course_cover',
        align:'center',
        render: data => (
          <img src={data} className="course-cover-pic"/>
        )
      },{
        title: '专栏类目',
        align: 'center',
        dataIndex: 'name',
      }, {
        title: '订阅类型',
        dataIndex: 'is_free',
        align: 'center',
        render:(text,data)=>(
          <span className={data.is_free==0?"green-font":''}>{data.is_free==0?'需付费':'免费'}</span>
        )
      }, {
        title: '订阅规则',
        align: 'center', 
        dataIndex: 'price_rule',
        render:(text,data)=>{
          if(data.is_free==0&&text){

            let price_rule_view=text.map((item)=>{
              return <div className="green-font">订阅{item.time}个月，收费{item.price}元</div>
            })
            return price_rule_view
          }else{
            return <span>无</span>
          }
          
        }
      },{
        title: '操作',
        key: 'action',
        align: 'center',
        render: (text, record, index) => (
          <div className="min-width-controll">
            <Button type="primary" onClick={this.edit.bind(this, record)} >详情</Button>
            <Divider type="vertical" />
            <Button type="primary" onClick={this.statistic.bind(this, record)} >统计</Button>
            <Divider type="vertical" />
            <Button type="primary" onClick={this.add_video.bind(this, record)} >内容</Button>
            <Divider type="vertical" />
            <Button type="primary" onClick={this.modal_show.bind(this, record.id)} >广告</Button>
            <Divider type="vertical" />
            <Popconfirm title="是否下架专栏？" okText="是" cancelText="否" onConfirm={this.delete.bind(this, record, index)}>
              <Button type="primary">下架</Button>
            </Popconfirm>

          </div>
        ),
      }];
     
     
    if (this.state.goto_course_add) {
      return (
        <Redirect push to="/course/add" />
      );
    }
    if (this.state.goto_another_page) {
      return (
        <Redirect push to={this.state.goto_another_page_path} />
      );
    }

    return (

      <Card >
        <div className="form-search-div">
          <Form layout="inline" className="form-login">

            <FormItem label="专栏名称">
              {
                getFieldDecorator('title', {

                })(
                  <Input placeholder="请输入专栏名称" className="input-style" onPressEnter={this.handleSubmit} />
                )
              }
            </FormItem>

            <FormItem
              label="专栏分类"
            >
              {getFieldDecorator('course_cate_id', {
              
              })(
                <Select
                  placeholder="请选择专栏分类"
            
                  style={{width:200}}
                >

                  {
                    this.state.course_cate.map((item) => {

                      return <Option key={item.id} >{item.title}</Option>
                    })

                  }
                </Select>
              )}
            </FormItem>

            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style">搜索</Button>
            </FormItem>
          </Form>
        </div>
        <div className="teacher-header-wrap">
          <Button type="primary" onClick={this.add} >新增专栏</Button>
        </div>

        <Table columns={columns} dataSource={this.state.data} className="table-view" pagination={{

pageSize:50,
}}/>

         {/* 权限配置组件 begin*/}
         <Modal
          title="广告配置"
          visible={this.state.modal_visible}
          onOk={this.submit_ad}
          onCancel={this.modal_hidden}
          okText="提交"
          cancelText="取消"
        >

            <Select
                mode="multiple"
                placeholder="请选择专栏"
                onChange={that.change_ad}
                style={{width:"100%"}}
                value={that.state.course_add_id}
                className="course-input-style"
              >
                {
                  this.state.course_ad.map((item) => {
                    return <Option key={item.id} >{item.title}</Option>
                  })
                }
              </Select>

       </Modal>
       {/* 权限配置组件 end*/}
      </Card>

    );
  }
}

Course_list = Form.create()(Course_list);
const mapStateToProps = state => {

  return {
    userinfo: state.userinfo,
    course_id:state.course_id
  }
};
export default connect(mapStateToProps)(Course_list)