import JsonP from 'jsonp'
import axios from 'axios'
import { Modal } from 'antd'
import { SERVER_PATH } from '../config/config'
export default class Axios {
    static jsonp(options) {
        return new Promise((resolve, reject) => {
            JsonP(options.url, {
                param: 'callback'
            }, function (err, response) {
                if (response.status == 'success') {
                    resolve(response);
                } else {
                    reject(response.messsage);
                }
            })
        })
    }
    // 封装axios请求
    static ajax(options) {
        let loading;
        if (options.data && options.data.isShowLoading !== false) {
            loading = document.getElementById('ajaxLoading');
            loading.style.display = 'block';
        }

        return new Promise((resolve, reject) => {

            axios({
                url: options.url,
                method: 'post',
                baseURL: SERVER_PATH,
                headers: {
                    'Content-type': 'multipart/form-data',

                },
                timeout: 10000,
                data: (options.data ? options.data : {})
            }).then((response) => {
                if (options.data && options.data.isShowLoading !== false) {
                    loading = document.getElementById('ajaxLoading');
                    loading.style.display = 'none';
                }
                if (response.status == '200') {
                    let res = response.data;
                    if (res.errno == '0') {
                        resolve(res);
                    } else {
                        Modal.info({
                            title: "提示",
                            content: res.errmsg
                        })
                    }
                } else {
                    reject(response.data);
                    loading = document.getElementById('ajaxLoading');
                    loading.style.display = 'none';
                }
            })
        });
    }

   
}