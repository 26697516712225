import React from 'react'
import './index.less'
import { connect } from "react-redux"
import { getBase64, uploadImageCallBack, get_new_filename } from '../../../utils/index'
import Axios from '../../../axios/index'
import { Redirect ,NavLink} from 'react-router-dom'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftjs from 'draftjs-to-html'
import { EditorState, ContentState } from 'draft-js';
import { client } from '../../../utils/oss'
import { OSS_IMAGE_PATH, ROOT_PATH } from '../../../config/config'
import htmlToDraft from 'html-to-draftjs';
import {
  Form, Input, Icon, Upload, Select, Modal, message, Button, InputNumber, Card, Switch
} from 'antd';
const Option = Select.Option;


const { TextArea } = Input;
class Course_edit extends React.Component {

  state = {
    image_id: "",
    loading: false,
    is_modify:false,
    teacher_list: [],
    course_info: {},
    course_cate: [],
    price_rule: [{ "time": '', "price": "" }],
    is_free: false,
    goto_course_info: false
  };
  onEditorChange = (editorContent) => {
    this.setState({
      editorContent,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    });
  };
  // 提交表单
  handleSubmit = (e) => {
    let that = this;
    let data = this.props.form.getFieldsValue();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (!that.check_price_rule()) {
          message.info(`订阅规则不能为空`)
          return false;
        }
        data.detail = draftjs(data.detail);
        data.course_id = this.state.course_info.id;
        data.token = this.props.userinfo.token;
        if (this.state.is_free) {
          data.is_free = 0;
          data.price_rule = JSON.stringify(that.state.price_rule).toString();
        } else {
          data.is_free = 1;
        }
        let course_info = this.state.course_info;
        let course_cate = this.state.course_cate;

        for (let i = 0; i < course_cate.length; i++) {

          if (course_cate[i].title == data.course_cate_id) {

            data.course_cate_id = course_cate[i].id;
            break;
          }

        }
        Axios.ajax({
          url: '/course/edit.php',
          data: data
        }).
          then((res) => {
            message.success(`保存成功！`)
            this.setState({
              goto_course_list: true
            })
          })
      }


    });
  }


  // 图片上传
  handleUploadChange = (info) => {

    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => this.setState({

        loading: false
      }));
    }
  }
  //上传图片校验
  beforeUpload = (file) => {

    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    if (!isJPG && !isPNG) {
      message.error('仅支持jpg或者png格式!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('文件需小于2MB!');
    }

    let that = this;
    let new_filename = get_new_filename(file.name);
    client.put(OSS_IMAGE_PATH + new_filename, file)
      .then(function (result) {
        message.success("上传成功");
        that.setState({
          imageUrl: ROOT_PATH + OSS_IMAGE_PATH + new_filename
        })
        that.props.form.setFieldsValue({
          course_cover: ROOT_PATH + OSS_IMAGE_PATH + new_filename
        });
      })
      .catch(function (err) {
        Modal.info({
          title: "提示",
          content: err.message
        })
      });


    return false;

  }
  // 监听订阅时间
  change_price_time = (value, index) => {
    let price_rule = this.state.price_rule;
    price_rule[index].time = value;
    this.setState({
      price_rule: price_rule
    })

  }
  // 监听订阅金额
  change_price_value = (value, index) => {
    let price_rule = this.state.price_rule;
    price_rule[index].price = value;
    this.setState({
      price_rule: price_rule
    })

  }
  // 减少收费规则
  sub_price_rule(index) {
    let price_rule = this.state.price_rule;

    price_rule.splice(index);
    this.setState({
      price_rule: price_rule
    })
  }
  // 增加收费规则
  add_price_rule() {
    let price_rule = this.state.price_rule;
    let tmp = {};
    tmp.price = '';
    tmp.time = "";
    price_rule.push(tmp);
    this.setState({
      price_rule: price_rule
    })
  }
  // 校验收费规则是否为空
  check_price_rule(){
    let price_rule = this.state.price_rule;
    let is_free=this.state.is_free;
    if(is_free){
      for(let item in price_rule){
        if(price_rule[item].time==''||price_rule[item].price=='') return false;
      }
    }
    
    return true;
  }
  componentWillMount() {
    let that = this;
    Axios.ajax({
      url: '/teacher/list.php',
      data: {
        token: this.props.userinfo.token,
        filter: true
      }
    }).
      then((res) => {
        this.setState({
          teacher_list: res.data
        })
      })
    this.init_course_cate();
    Axios.ajax({
      url: '/course/list.php',
      data: {
        token: this.props.userinfo.token,
        course_id: this.props.course_id
      }
    }).
      then((res) => {
        if (res.data == undefined) {
          this.setState({
            goto_course_list: true
          })
        } else {

          const blocksFromHtml = htmlToDraft(res.data.detail);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const editorContent = ContentState.createFromBlockArray(contentBlocks, entityMap);
          const editorState = EditorState.createWithContent(editorContent);
          let tmp_editorcontent = htmlToDraft(res.data.detail);

          this.setState({
            course_info: res.data,
            imageUrl: res.data.course_cover,
            editorContent: tmp_editorcontent,
            editorState: editorState
          })
          if (res.data.is_free == 0) {
            this.setState({
              is_free: true
            })
            // 设置订阅规则
            if (res.data.price_rule) {
              this.setState({
                price_rule: res.data.price_rule
              })

            }

          } else {
            this.setState({
              is_free: false
            })
          }

          this.props.form.setFieldsValue({
            title: res.data.title,
            price: res.data.price,
            pay180_price: res.data.pay180_price,
            pay365_price: res.data.pay365_price,
            name: res.data.name,
            tag: res.data.tag,
            teacher_id: res.data.teacher_id,
            course_cate_id: res.data.name,
            detail: tmp_editorcontent,
            course_cover: res.data.course_cover,
            product: res.data.product,
            product_annex: res.data.product_annex
          });
        }
      }).catch((res) => {
        this.setState({
          goto_course_list: true
        })
      })
  }
  onSwitchChange = (checked) => {
    this.setState({
      is_free: checked
    })
  }
  onchange_product_annex = (checked) => {
    this.setState({
      is_modify: checked
    })
  }
  // 初始化专栏分类
  init_course_cate() {
    let data = {};
    data.token = this.props.userinfo.token;
    Axios.ajax({
      url: '/course_cate/list.php',
      data: data
    }).
      then((res) => {
        let course_cate_arr = [];
        let tmp = { "id": "0", 'title': "全部分类" };
        course_cate_arr.push(tmp);
        for (let i = 0; i < res.data.length; i++) {
          let tmp2 = {};
          tmp2.id = res.data[i].id;
          tmp2.title = res.data[i].title;
          course_cate_arr.push(tmp2);
        }
        this.setState({
          course_cate: course_cate_arr
        })
      })

  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { editorContent, editorState } = this.state;
    let that = this;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;


    if (this.state.goto_course_list) {
      return (
        <Redirect to="/course/list" />
      );
    }
    // 设置收费规则
    let price_rule = that.state.price_rule;
    let price_rule_view = null;
    if (price_rule) {
      price_rule_view = price_rule.map((item, index) => {
        return (<div className="price-rule-row-view">
          订阅时间(月)：
          <InputNumber min={1} max={36} placeholder="请输入订阅时限" style={{ width: 150 }} value={item.time} onChange={(value) => { that.change_price_time(value, index) }} />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;订阅费用(元)：
          <InputNumber min={0.1} max={50000} placeholder="请输入订阅费用" style={{ width: 150 }} onChange={(value) => { that.change_price_value(value, index) }} value={item.price} />
          {/* 新增或者减少订阅规则 */}
          {index != 0 && index == price_rule.length - 1 ? <Button type="primary" className="price-rule-font" onClick={that.sub_price_rule.bind(this, index)}>删除订阅规则</Button> : ''}
          {index == price_rule.length - 1 ? <Button type="primary" className="price-rule-font" onClick={that.add_price_rule.bind(this)}>新增订阅规则</Button> : ''}
        </div>)
      })
    }

    return (
      <Card  className="window-view " title={<div className="window-header-view" >
      <NavLink to="/course/list"><Button type="primary" className="window-close-view"><Icon type='arrow-left' />返回</Button></NavLink>
      <div className="window-title-view">编辑专栏</div>
    </div>}>
      <div className="course-content-wrap">
        <Form className="course-form-teacher-add" >
          <Form.Item
            label="专栏名称"
          >
            {getFieldDecorator('title', {
              rules: [{
                required: true, message: '专栏名称不能为空',
              }],
            })(
              <Input placeholder="请输入专栏名称" className="course-input-style" />
            )}
          </Form.Item>
          <Form.Item
            label="专栏分类"
          >
            {getFieldDecorator('course_cate_id', {
              rules: [{
                required: true, message: '专栏分类不能为空',
              }]
            })(
              <Select
                placeholder="请选择专栏分类"

                className="course-input-style"
              >
                {
                  this.state.course_cate.map((item) => {

                    return <Option key={item.id} value={item.id} >{item.title}</Option>
                  })
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="专栏讲师"
          >
            {getFieldDecorator('teacher_id', {
              rules: [{
                required: true, message: '专栏讲师不能为空',
              }]
            })(
              <Select
                mode="multiple"
                placeholder="请添加专栏相关讲师"
                className="course-input-style"
              >
                {
                  this.state.teacher_list.map((item) => {
                    return <Option key={item.id} >{item.nickname}</Option>
                  })
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="专栏标签"
          >
            {getFieldDecorator('tag', {
            })(
              <Input placeholder="请输入专栏标签，不多于15字" className="course-input-style" />
            )}
          </Form.Item>
          <Form.Item label="专栏封面">
            {getFieldDecorator('course_cover', {
            })(
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}

                beforeUpload={this.beforeUpload}
                onChange={this.handleUploadChange}>
                {imageUrl ? <img src={imageUrl} className="course-avatar-pic" /> : uploadButton}
              </Upload>
            )}
          </Form.Item>
          <Form.Item
            label="专栏详情" className="detail-view"
          >
            {getFieldDecorator('detail', {
              rules: [{
                required: true, message: '专栏详情不能为空',
              }],

            })(
              <Editor
                editorState={editorState}
                onContentStateChange={this.onEditorChange}
                onEditorStateChange={this.onEditorStateChange}
                editorClassName="course-wrapper"
                toolbar={{
                  image: {
                    uploadCallback: uploadImageCallBack,
                    previewImage: true,
                  }
                }}
              />
            )}
          </Form.Item>
          <Form.Item
            label="是否付费"
          >
            {getFieldDecorator('is_free', {

            })(
              <Switch checkedChildren="付费" unCheckedChildren="免费" onChange={this.onSwitchChange} checked={this.state.is_free} />
            )}
          </Form.Item>
          {this.state.is_free ? <Form.Item
            label="订阅规则"
          >{price_rule_view} </Form.Item> : ''}
          <Form.Item
            label="专栏对应产品编号"
          >
            {getFieldDecorator('product', {

            })(
              <Input placeholder="请输入产品编号" className="course-input-style" />
            )}
          </Form.Item>
          <Form.Item
            label="产品附件"
          >
            {getFieldDecorator('product_annex', {

            })(
              <TextArea placeholder="请输入产品附件信息" className="course-textarea-style" disabled={!this.state.is_modify}/>
            )}
            <Switch checkedChildren="可写" unCheckedChildren="只读" onChange={this.onchange_product_annex} checked={this.state.is_modify} style={{marginLeft:'30px'}}/>
          </Form.Item>
          <Form.Item className="layout-center">
            <Button type="primary" onClick={this.handleSubmit}>保存</Button>
          </Form.Item>

        </Form>
        </div>
      </Card>
    );
  }
}

Course_edit = Form.create()(Course_edit);



const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    course_id: state.course_id
  }
};
export default connect(mapStateToProps)(Course_edit)