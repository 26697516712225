import React from 'react'
import './index.less'
import { connect } from "react-redux"

import Axios from '../../../axios/index'
import { Redirect,NavLink } from 'react-router-dom'
import  {getBase64,getUrlParam,get_new_filename} from '../../../utils/index'
import { client } from '../../../utils/oss'
import { OSS_IMAGE_PATH, ROOT_PATH } from '../../../config/config'
import {
  Form, Input, Icon, Upload, message, Button, Modal, Card
} from 'antd';


const { TextArea } = Input;
class Course_cate_edit extends React.Component {
  state = {
    course_cate_info: {},
    loading: false,
    goto_course_cate_list: false,

  };
       //上传图片校验
       beforeUpload=(file)=> {
  
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        if (!isJPG && !isPNG) {
            message.error('仅支持jpg或者png格式!');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('文件需小于2MB!');
        }
         
        let that = this;
        let new_filename=get_new_filename(file.name);
        client.put(OSS_IMAGE_PATH+new_filename, file)
          .then(function (result) {
            message.success("上传成功");
            that.setState({
              imageUrl:ROOT_PATH+OSS_IMAGE_PATH+new_filename
            })
            that.props.form.setFieldsValue({
              pic_url:ROOT_PATH+OSS_IMAGE_PATH+new_filename
            });
          })
          .catch(function (err) {
            Modal.info({
              title: "提示",
              content: err.message
            })
          });
      
      
        return false;
        
      }
  // 提交表单
  handleSubmit = (e) => {
    let data = this.props.form.getFieldsValue();
    console.log(data);
    this.props.form.validateFields((err, values) => {
      if (!err) {

        data.id = this.state.course_cate_info.id;
        data.token = this.props.userinfo.token;
   
      console.log(data);


        if (data.pic_url==this.state.course_cate_info.pic_url  && data.title == this.state.course_cate_info.title && data.introduce == this.state.course_cate_info.introduce) {
          this.setState({
            goto_course_cate_list: true
          })

          return false;
        } else {

          Axios.ajax({
            url: '/course_cate/edit.php',
            data: data
          }).
            then((res) => {
              message.success(`保存成功！`)
              this.setState({
                goto_course_cate_list: true
              })
            })
        }

      }
    });
  }


  // 图片上传
  handleChange = (info) => {

    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => this.setState({
        imageUrl,
        loading: false,
      }));
    }
  }
  componentWillMount() {

    let course_cate_id = this.props.course_cate_id;

    // 初始化分类数据
    if (course_cate_id) {
      Axios.ajax({
        url: '/course_cate/list.php',
        data: {
          token: this.props.userinfo.token,
          course_cate_id: course_cate_id
        }
      }).
        then((res) => {
          if (res.data == undefined) {
            this.setState({
              goto_course_cate_list: true
            })
          } else {
            this.setState({
              course_cate_info: res.data,
              imageUrl: res.data.pic_url
            })
            this.props.form.setFieldsValue({
              pic_url:res.data.pic_url,
              title: res.data.title
            });
          }
        }).catch((res) => {
          this.setState({
            goto_course_cate_list: true
          })
        })
    }

  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;

    if (this.state.goto_course_cate_list) {
      return (
        <Redirect to="/course_cate/list" />
      );
    }


    return (


      <Card  className="window-view " title={<div className="window-header-view" >
        <NavLink to="/course_cate/list"><Button type="primary" className="window-close-view"><Icon type='arrow-left' />返回</Button></NavLink>
        <div className="window-title-view">编辑分类</div>
      </div>}>
        <div className="content-wrap">
        <Form className="form-teacher-add" >
          <Form.Item
            label="分类名称"
          >
            {getFieldDecorator('title', {
              rules: [{
                required: true, message: '名称不能为空',
              }],

            })(
              <Input placeholder="请输入分类名称" />
            )}
          </Form.Item>
        
          <Form.Item label="分类图标">
            {getFieldDecorator('pic_url', {

            })(
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
        
                beforeUpload={this.beforeUpload}>
                {imageUrl ? <img src={imageUrl} className="course-cate-pic" /> : uploadButton}
              </Upload>
            )}

          </Form.Item>

          <Form.Item className="layout-center">
            <Button type="primary" onClick={this.handleSubmit}>保存</Button>
          </Form.Item>

        </Form>
        </div>
      </Card>
    );
  }
}

Course_cate_edit = Form.create()(Course_cate_edit);



const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    course_cate_id:state.course_cate_id
  }
};
export default connect(mapStateToProps)(Course_cate_edit)