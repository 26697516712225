import React from 'react'
import { Row, Col, Breadcrumb,Menu, Icon, Button } from 'antd';
import './index.less';
import { quit } from '../../redux/action'
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import cookie from 'react-cookies'
import { menuList } from './../../config/menuConfig'
import * as ACTION from './../../redux/action/index'
import {set_router_path} from '../../redux/action'

class Header extends React.Component {
    state = {
        currentKey: []
    }
    // 退出登录
    quit_account = () => {
        const { dispatch } = this.props;
        cookie.remove("userinfo");
        dispatch(quit());
    }
    change_company(){
        let that=this;
        let userinfo=this.props.userinfo;
        userinfo.company_id='';
        userinfo.company_name='';
        const {dispatch}=this.props;
        // dispatch(ACTION.set_userinfo(userinfo));
        // cookie.save("userinfo", userinfo);
    }
    // 切换路由
    change_path=( item)=>{

        const {dispatch}=this.props;
        dispatch(set_router_path(item.key))
  }
    render() {
        let that=this;
        let router_path=this.props.router_path;
        let breadrumb=[{path:'/sxcm_game/home',title:'首页'}];
        for (let k in menuList) {
            if(menuList[k].key==router_path){
                let tmp={};
                tmp.path=menuList[k].key;
                tmp.title=menuList[k].title;
                breadrumb.push(tmp);
                break;
            }
            let gate=false;
            // 遍历子节点
            if(menuList[k].children){
                for (let k2 in menuList[k].children) {
                    if (menuList[k].children[k2].key==router_path) {
                        let tmp={};
                        tmp.path=menuList[k].children[k2].key;
                        tmp.title=menuList[k].children[k2].title;
                        breadrumb.push(tmp);
                        gate=true
                        break;
                    }
                }
            }
            if(gate){
                break;
            }
        }
        let length=breadrumb.length;
        let breadrumb_view=breadrumb.map((item,index)=>{
            if(index==length-1){
                return (<Breadcrumb.Item > {item.title}</Breadcrumb.Item>)
            }
            return (<Breadcrumb.Item href={item.path}> {item.title}</Breadcrumb.Item>)
        })


        return (
            <Row className="header">
                <Col span={16} className="header-left" >
                 
                    <Breadcrumb separator=">" >
                        {breadrumb_view}
                    </Breadcrumb>
                </Col>
                <Col span={8} className="header-right">
              
                    
                    <span className="username">{this.props.userinfo.username}</span>
                    <a href="#" className="qiut-login" onClick={this.quit_account}>退出</a>
                </Col>
            </Row>
        );
    }
}
const mapStateToProps = state => {
    return {
        userinfo: state.userinfo,
        router_path:state.router_path
    }
};
export default connect(mapStateToProps)(Header)