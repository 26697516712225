import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import { NavLink } from 'react-router-dom'
import { Table, Divider, Button, Card, message, InputNumber, Form, Input, Modal } from 'antd';

import  { set_current_user_id ,set_router_path}  from  './../../../redux/action'
import { connect } from "react-redux"
const FormItem = Form.Item;
class Distribute_list extends React.Component {
  state = {
    goto_teacher_add: false,
    goto_teacher_edit: false,
    visible: false,
    goto_teacher_edit_path: '',
    send_day: '',
    current_id: '',
    withdraw_amount: 0,
    data: []
  }
  // 赠送时长
  send_time(data) {
    this.setState({
      current_id: data.id,
      visible: true
    })
  }
  // 跳转到用户详情
  goto_detail = (data) => {
    // message.info("开发中，敬请期待！")
    const {dispatch}= this.props;
    dispatch(set_current_user_id(data.promot_id));
    // dispatch(set_router_path("/user/list"));
  }
  // 监听赠送时长
  change_send_day(v) {
    this.setState({
      send_day: v
    })
  }
  // 提交赠送时长
  handle_send_day=()=> {
    let that = this;
    let data = {};
    data.token =  this.props.userinfo.token;
    data.subscribe_id = that.state.current_id;
    data.send_day=that.state.send_day;
    Axios.ajax({
      url: '/subscribe/give.php',
      data: data
    }).
      then((res) => {
        message.success("赠送成功");
        that.setState({
          visible:false,
          send_day:''
        })
        that.init_data();
      })
  }
  // 取消赠送时长
  handleCancel = () => {
    this.setState({
      visible: false,
      send_day: ''
    })
  }
  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    this.init_data(data.nickname);
  }
  componentWillMount() {

    this.init_data();
  }
  // 初始化数据
  init_data(nickname = '') {
    Axios.ajax({
      url: '/distribute/list.php',
      data: {
        token: this.props.userinfo.token,
        nickname: nickname
      }
    }).
      then((res) => {
        this.setState({
          data: res.data.list
        })
      })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    let that = this;
    const columns = [{
      title: '推广用户',
      dataIndex: 'nickname',
      align: 'center',
    }, {
      title: '推广专栏',
      align: 'center',
      dataIndex: 'title',
    },{
      title: '佣金奖励(元)',
      align: 'center',
      dataIndex: 'promot_amount',
      render:(text)=>{
        return (<span style={{color:'green'}}>{text}</span>)
      }
    }, {
      title: '推广佣金比例(%)',
      align: 'center',
      dataIndex: 'promot_rate',
    },{
      title: '订阅用户',
      align: 'center',
      dataIndex: 'promot_name',
      render:(text,data)=>{
         return (<NavLink to="/user/detail"><span style={{color:'green'}} onClick={this.goto_detail.bind(this, data)}>{text}</span></NavLink>)
      }
    }, {
      title: '专栏实付金额(元)',
      align: 'center',
      dataIndex: 'total_amount',
    }
    ,  
    {
      title: '推广时间',
      align: 'center',
      dataIndex: 'create_time',
    }
    ];


    return (

      <Card >
        <div className="form-search-div">
          <Form layout="inline" className="form-login">
            <FormItem label="用户昵称">
              {
                getFieldDecorator('nickname', {
                })(
                  <Input placeholder="请输入用户昵称" className="input-style" style={{ width: '300px' }} onPressEnter={this.handleSubmit} />
                )
              }
            </FormItem>
            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style" >搜索</Button>
            </FormItem>
          </Form>
        </div>
          
        <Table columns={columns} dataSource={this.state.data} className="table-view" />
      </Card>

    );
  }
}

Distribute_list = Form.create()(Distribute_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Distribute_list)