
import {
    message
} from 'antd';
import { client } from './oss'
import { OSS_IMAGE_PATH, ROOT_PATH } from '../config/config'
// base64转码
export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}




//获取参数
export function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]); return null;
    return null;
}

// 生成base64图片
export function uploadImageCallBack(file) {
    return new Promise(
        (resolve, reject) => {

            let new_filename = OSS_IMAGE_PATH + get_new_filename(file.name);
            client.put(new_filename, file)
                .then(function (result) {
                    // 成功
                    resolve({
                        data: {
                            link: ROOT_PATH + new_filename
                        }
                    })
                })
                .catch(function (err) { message.error(err.message); });

        });

}

//生成唯一订单号
export function produce_order() {
    let mydate = new Date();
    return "jk" + mydate.getFullYear() + (mydate.getMonth() + 1) + mydate.getDay() + mydate.getHours() + mydate.getMinutes() + mydate.getSeconds() + mydate.getMilliseconds() + Math.round(1000 + Math.random() * 8000);
}

// 获取文件后缀名
export function get_suffix(filename) {
    let index1 = filename.lastIndexOf(".");
    let index2 = filename.length;
    let suffix = filename.substring(index1 + 1, index2);
    return suffix;
}
// 获取上传文件名
export function get_new_filename(filename) {

    let suffix = get_suffix(filename);
    let new_filename = produce_order();
    let mydate = new Date();
    return mydate.getFullYear() + "." + (mydate.getMonth() + 1) + "." + mydate.getDay() + "/" + new_filename + "." + suffix;
}


// 获取最新
export function getDay(day) {
    var today = new Date();
    var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(targetday_milliseconds); //注意，这行是关键代码

    var tDate = today.getDate();
    var tMonth = today.getMonth();

    tMonth = tMonth + 1;
    return tMonth + "/" + tDate;
}
// 判断是否为数字
export function isNumber(val) {
    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    if (regPos.test(val) || regNeg.test(val)) {
        return true;
    } else {
        return false;
    }
}