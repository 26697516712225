/*
 * action 类型
 */

export const type = {
    LOGIN : 'LOGIN',
    QUIT : 'QUIT',
    COURSE_ID:"COURSE_ID",
    TEACHER_ID:"TEACHER_ID",
    SET_SHOP_ID:"SET_SHOP_ID",
    COURSE_CATE_ID:"COURSE_CATE_ID",
    SET_AGENT_ROLE_ID:'SET_AGENT_ROLE_ID',
    SET_AGENT_USER_ID:'SET_AGENT_USER_ID',
    SET_COURSE_CONTENT_ID:"SET_COURSE_CONTENT_ID",
    SET_ROUTER_PATH:"SET_ROUTER_PATH",
    SET_CURRENT_USER_ID:"SET_CURRENT_USER_ID",
    SET_CURRENT_PAGE:"SET_CURRENT_PAGE"
    
}

// 登录
export function login(userinfo) {
    return {
        type:type.LOGIN,
        userinfo:userinfo
    }
}
//注销
export function quit() {
    return {
        type:type.LOGIN,
        userinfo:null
    }
}
// 设置课程编号
export function set_course_id(course_id) {
    return {
        type:type.COURSE_ID,
        course_id:course_id
    }
}

// 设置老师编号
export function set_teacher_id(teacher_id) {
    return {
        type:type.TEACHER_ID,
        teacher_id:teacher_id
    }
}

// 设置分类编号
export function set_course_cate_id(course_cate_id) {
    return {
        type:type.COURSE_CATE_ID,
        course_cate_id:course_cate_id
    }
}

// 设置老师编号
export function set_shop_id(shop_id) {
    return {
        type:type.SET_SHOP_ID,
        shop_id:shop_id
    }
}

// 设置专栏内容id
export function set_course_content_id(course_content_id) {
    return {
        type:type.SET_COURSE_CONTENT_ID,
        course_content_id:course_content_id
    }
}

// 设置角色id
export function set_agent_role_id(agent_role_id) {
    return {
        type:type.SET_AGENT_ROLE_ID,
        agent_role_id:agent_role_id
    }
}

// 设置代理用户id
export function set_agent_user_id(agent_user_id) {
    return {
        type:type.SET_AGENT_USER_ID,
        agent_user_id:agent_user_id
    }
}

// 设置路由路径
export function set_router_path(router_path){
    return {
        type:type.SET_ROUTER_PATH,
        router_path:router_path
    }
}

// 设置当前用户id
export function set_current_user_id(current_user_id){
    return {
        type:type.SET_CURRENT_USER_ID,
        current_user_id:current_user_id
    }
}

// 更新页面
export function set_current_page(current_page){
    return {
        type:type.SET_CURRENT_PAGE,
        current_page:current_page
    }
}