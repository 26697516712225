import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import QRCode from 'qrcode.react';
import { Table, InputNumber, Modal, Button, Card, message, Popconfirm, Form, Input, Divider } from 'antd';
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { SERVER_ROOT } from '../../../config/config'
const FormItem = Form.Item;
class Coupon_get_list extends React.Component {
  state = {
    nickname:'',
    data: []
  }

  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    this.init_data(data.nickname);
  }
  componentWillMount() {

     this.init_data();
  }
  // 初始化数据
  init_data(nickname = '') {
    let that = this;
    Axios.ajax({
      url: '/coupon/user_list.php',
      data: {
        token: this.props.userinfo.token,
        nickname: nickname
      }
    }).
      then((res) => {
        this.setState({
          data: res.data
        })
      })
  }
  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: '用户昵称',
        dataIndex: 'nickname',
        align: 'center'
  
      },{
      title: '优惠券类型',
      dataIndex: 'title',
      align: 'center'

    }, {
      title: '优惠金额(元)',
      dataIndex: 'amount',
      align: 'center'

    }, {
      title: '当前状态',
      dataIndex: 'status',
      align: 'center',
      render:(text)=>{
        if(text=="GAIN"){
          return (
            <span className="coupon-font1">已领取</span>
          )
        }else if(text=="DESTRORY"){
          return (
            <span>已核销</span>
          )
        }else if(text=="EXPIRE"){
          return (
            <span >已失效</span>
          )
        }
      }
    }, {
      title: '到期时间',
      align: 'center',
      dataIndex: 'expire_time',
    }, {
      title: '领取时间',
      align: 'center',
      dataIndex: 'create_time',
    }, 

    ];


    return (

      <Card >
        <div className="form-search-div">
          <Form layout="inline" className="form-login">

            <FormItem label="用户昵称">
              {
                getFieldDecorator('nickname', {

                })(
                  <Input placeholder="请输入用户昵称" className="input-style" style={{ width: '300px' }} onPressEnter={this.handleSubmit} />
                )
              }
            </FormItem>
            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style" >搜索</Button>
            </FormItem>
          </Form>
        </div>


        <Table columns={columns} dataSource={this.state.data} className="table-view" />

      
      </Card>

    );
  }
}

Coupon_get_list = Form.create()(Coupon_get_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Coupon_get_list)