import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import {set_course_content_id} from '../../../redux/action'
import { Table, Divider, Button, Card, message, Popconfirm, Select, Form, Input,Icon } from 'antd';
import { getUrlParam } from '../../../utils/index'
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import * as ACTION from '../../../redux/action/'
const FormItem = Form.Item;
const Option = Select.Option;
class Course_list extends React.Component {
  state = {
    goto_video_add: false,
    current_page:1,
    data: []
  }
  // 新增视频
  add = (e) => {
    this.setState({ goto_video_add: true });
    const { dispatch } = this.props;
    dispatch(ACTION.set_current_page(this.state.current_page));
  }
  // 编辑
  edit(data) {
    const { dispatch } = this.props;
    dispatch(set_course_content_id(data.id));
    dispatch(ACTION.set_current_page(this.state.current_page));
  }

  // 删除
  delete(index) {

    Axios.ajax({
      url: '/video/delete.php',
      data: {
        token: this.props.userinfo.token,
        video_id: index.id
      }
    }).
      then((res) => {
        message.success("移除成功");
 
 
        this.init_data();
      })

  }
  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();

          this.init_data(data);


  }
  componentWillMount() {
    let that=this
      this.init_data();
      this.setState({
        current_page:that.props.current_page
      })

  }
  // 初始化数据
  init_data(data={}) {
    data.token=this.props.userinfo.token;
    data.course_id=this.props.course_id;
    if(data.course_id){
      Axios.ajax({
        url: '/video/list.php',
        data: data
      }).
        then((res) => {
          this.setState({
            data: res.data
          })
        })
    }
    
  }
   // 监听页面切换
   change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        align: 'center',
      },
      {
        title: '内容名称',
        dataIndex: 'title',
        align: 'center',
      },  {
        title: '内容类型',
        dataIndex: 'type',
        align:'center',
        render: data => (
          <div>{data=='VIDEO'?'视/音频':'图文'}</div>
        )
      },{
        title: '是否试看',
        dataIndex: 'is_free',
        align: 'center',
        render: data => <span>{data==0?'否':'是'}</span>
        
      } ,{
        title: '标签',
        dataIndex: 'tag',
        align: 'center',
      }, {
        title: '创建时间',
        dataIndex: 'create_time',
        align: 'center',

      }, {
        title: '操作',
        key: 'action',
        align: 'center',
        render: (text, record, index) => (
          <span>
            {/* <Button type="primary" onClick={this.edit.bind(this, record)} >修改详情</Button>
            <Divider type="vertical" />
            <Button type="primary" onClick={this.add_video.bind(this, record)} >添加视频</Button>
            <Divider type="vertical" /> */}
            <NavLink to="/video/edit"><Button type="primary" onClick={this.edit.bind(this, record)} >编辑</Button></NavLink>
            <Divider type="vertical" />
            <Popconfirm title="是否移除内容？" okText="是" cancelText="否" onConfirm={this.delete.bind(this, record)}>
              <Button type="primary">移除</Button>
            </Popconfirm>

          </span>
        ),
      }];

    if (this.props.course_id==null) {
      return (
        <Redirect to="/course/list" />
      );
    }

    return (
      <Card  className="window-view " title={<div className="window-header-view" >
      <NavLink to="/course/list"><Button type="primary" className="window-close-view"><Icon type='arrow-left' />返回</Button></NavLink>
      <div className="window-title-view">专栏内容列表</div>
    </div>}>

    <div className="course-content-wrap">  
        <NavLink className="teacher-header-wrap2" to="/video/add">
          <Button type="primary" onClick={this.add} >新增内容</Button>
        </NavLink>

        <Table columns={columns} dataSource={this.state.data} className="table-view"  pagination={{
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:50,
        }}/>
        </div>
      </Card>

    );
  }
}

Course_list = Form.create()(Course_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    course_id:state.course_id,
    current_page:state.current_page
  }
};
export default connect(mapStateToProps)(Course_list)