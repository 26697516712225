import React from 'react'
import './index.less'
import Axios from '../../axios/index'
import { Table, Divider, Button, Card, message, Popconfirm, Form, Input } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import { set_Agent_withdraw_record_id} from '../../redux/action'
const FormItem = Form.Item;
class Agent_withdraw_record extends React.Component {
  state = {
    data: [],
    count:0
  }
  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    this.init_data(data.mobile);
  }
  componentWillMount() {
    this.init_data();
  }
  // 初始化数据
  init_data(mobile = '') {
    Axios.ajax({
      url: '/agent_bonus/withdraw_list.php',
      data: {
        token: this.props.userinfo.token,
        mobile:mobile
      }
    }).
      then((res) => {
        this.setState({
          data: res.data.list,
          count:res.data.count
  
        })
      })
  }
  // 发放代理商奖励
  withdraw=(data)=>{

  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '代理商昵称',
      dataIndex: 'nickname',
      align: 'center',
    }, {
      title: '手机号',
      dataIndex: 'mobile',
      align: 'center',
    },{
      title: '发放奖励金额(元)',
      dataIndex: 'withdraw_amount',
      align: 'center',
    },{
      title: '创建时间',
      dataIndex: 'create_time',
      align: 'center'
    }
    ];
    return (
      <Card >
        <div className="form-search-div">
          <Form layout="inline" className="form-login">

            <FormItem label="代理商手机号">
              {
                getFieldDecorator('mobile', {

                })(
                  <Input placeholder="请输入代理商手机" className="input-style" onPressEnter={this.handleSubmit}/>
                )
              }

            </FormItem>

            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style">搜索</Button>
            </FormItem>
          </Form>
        </div>
        
        <div className="teacher-header-wrap">
          累计发放奖励总金额 : {this.state.count}元
                </div>
        <Table columns={columns} dataSource={this.state.data} className="table-view" />
      </Card>

    );
  }
}

Agent_withdraw_record = Form.create()(Agent_withdraw_record);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Agent_withdraw_record)