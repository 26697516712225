import React from 'react'
import  './index.less'
import  { connect } from "react-redux"
import Axios from '../../../axios/index'
import { Redirect,NavLink } from 'react-router-dom'

import  {getBase64,uploadImageCallBack,get_new_filename} from '../../../utils/index'
import { client } from '../../../utils/oss'
import { OSS_IMAGE_PATH, ROOT_PATH } from '../../../config/config'
import {
    Form, Input,  Icon, Upload, message, Button, Modal,Card
  } from 'antd';
  

  const { TextArea } = Input;


  

  class Teacher_add extends React.Component {
    state = {

      loading: false,
      goto_teacher_list:false
    };
        //上传图片校验
beforeUpload=(file)=> {
  
  const isJPG = file.type === 'image/jpeg';
  const isPNG = file.type === 'image/png';
  if (!isJPG && !isPNG) {
      message.error('仅支持jpg或者png格式!');
      return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
      message.error('文件需小于2MB!');
  }
   
  let that = this;
  let new_filename=get_new_filename(file.name);
  client.put(OSS_IMAGE_PATH+new_filename, file)
    .then(function (result) {
      message.success("上传成功");
      that.setState({
        imageUrl:ROOT_PATH+OSS_IMAGE_PATH+new_filename
      })
      that.props.form.setFieldsValue({
        headimage:ROOT_PATH+OSS_IMAGE_PATH+new_filename
      });
    })
    .catch(function (err) {
      Modal.info({
        title: "提示",
        content: err.message
      })
    });


  return false;
  
}
    // 提交表单
    handleSubmit = (e) => {
        let data = this.props.form.getFieldsValue();
        data.token=this.props.userinfo.token;
       this.props.form.validateFields((err, values) => {
        if (!err) {
         
            Axios.ajax({
              url:'/teacher/add.php',
              data:data
            }).
            then((res)=>{
                    message.success(`新增讲师成功！`)
                    this.setState({
                        goto_teacher_list:true
                    })
            })
        }
      });
    }
  



    render() {
      const { getFieldDecorator } = this.props.form;
      const uploadButton = (
        <div>
          <Icon type={this.state.loading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">上传</div>
        </div>
      );
      const imageUrl = this.state.imageUrl;
      
      if(this.state.goto_teacher_list){
        return (
            <Redirect to="/teacher/list"/>
        );
      }
      return (

        
          <Card  className="window-view " title={<div className="window-header-view" >
          <NavLink to="/teacher/list"><Button type="primary" className="window-close-view"><Icon type='arrow-left' />返回</Button></NavLink>
          <div className="window-title-view">新增讲师</div>
        </div>}>
          <div className="content-wrap">
        <Form  className="form-teacher-add" >
          <Form.Item
            label="讲师昵称"
          >
            {getFieldDecorator('nickname', {
              rules: [{
                required: true, message: '讲师昵称不能为空',
              }],
            })(
              <Input placeholder="请输入讲师昵称"/>
            )}
          </Form.Item>
          <Form.Item
            label="简介"
          >
            {getFieldDecorator('introduce', {
              rules: [{
                required: true, message: '简介不能为空',
              }],
            })(
              <TextArea placeholder="请输入讲师简介"/>
            )}
          </Form.Item>
          <Form.Item label="头像">
          {getFieldDecorator('headimage', {
              rules: [{
                required: true, message: '头像不能为空',
              }],
            })(
                <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}

                beforeUpload={this.beforeUpload}
              >
                {imageUrl ? <img src={imageUrl} alt="avatar"  className="avatar-pic"/> : uploadButton}
            </Upload>
            )}

          </Form.Item>

          <Form.Item className="layout-center">
            <Button type="primary" onClick={this.handleSubmit}>提交</Button>
          </Form.Item>

        </Form>
        </div>
        </Card>
      );
    }
  }
  
Teacher_add = Form.create()(Teacher_add);
  


const mapStateToProps = state => {
  return {
      userinfo: state.userinfo
  }
};
export  default  connect(mapStateToProps)(Teacher_add)