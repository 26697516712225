import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import {
  Form, Input, Icon, Upload, Select, Modal, message, Button, InputNumber, Card
} from 'antd';
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { getBase64,  uploadImageCallBack,get_new_filename } from '../../../utils/index'
import { OSS_IMAGE_PATH, ROOT_PATH } from '../../../config/config'
import { client } from '../../../utils/oss'
const FormItem = Form.Item;
class Consult_server extends React.Component {
  state = {
    data: [],
    imageUrl:''
  }
  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    if (data.mobile == this.state.data.mobile && data.server_qr_code == this.state.data.server_qr_code) {
      return false;
    }

    data.token = this.props.userinfo.token;
    Axios.ajax({
      url: '/setting/edit.php',
      data: data
    }).
      then((res) => {
        message.success("修改成功");
        this.init_data();

      })
  }
  componentWillMount() {

    this.init_data();
  }

  // 图片上传
  handleUploadChange = (info) => {

    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => this.setState({

        loading: false
      }));
    }
  }
  //上传图片校验
  beforeUpload = (file) => {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isGIF = file.type === 'image/gif';
    if (!isJPG && !isPNG&& !isGIF) {
      message.error('仅支持jpg、gif、png格式!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('文件需小于2MB!');
    }

    let that = this;
    let new_filename = get_new_filename(file.name);
    client.put(OSS_IMAGE_PATH + new_filename, file)
      .then(function (result) {
        message.success("上传成功");
        that.setState({
          imageUrl: ROOT_PATH + OSS_IMAGE_PATH + new_filename
        })
        that.props.form.setFieldsValue({
          server_qr_code: ROOT_PATH + OSS_IMAGE_PATH + new_filename
        });
      })
      .catch(function (err) {
        Modal.info({
          title: "提示",
          content: err.message
        })
      });


    return false;

  }
  // 初始化数据
  init_data(mobile = '') {
    Axios.ajax({
      url: '/setting/list.php',
      data: {
        token: this.props.userinfo.token
      }
    }).
      then((res) => {
        this.setState({
          data: res.data,
          imageUrl:res.data.server_qr_code
        })
        this.props.form.setFieldsValue({
          mobile: res.data.mobile,
          server_qr_code: res.data.server_qr_code,
         
        });
      })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;

    return (
      <Card className="content-wrap">
        <Form className="setting-form-teacher-add" >
          <Form.Item
            label="客服电话"
          >
            {getFieldDecorator('mobile', {
            })(
              <Input placeholder="请输入客服电话" className="course-input-style" />
            )}
          </Form.Item>

          <Form.Item
            label="上传微信客服二维码图片"
          >
            {getFieldDecorator('server_qr_code', {
            })(
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}

                beforeUpload={this.beforeUpload}
                onChange={this.handleUploadChange}>
                {imageUrl ? <img src={imageUrl} className="server-qrcode-pic" /> : uploadButton}
              </Upload>
            )}
          </Form.Item>
          <Form.Item className="layout-center">
            <Button type="primary" onClick={this.handleSubmit}>保存</Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

Consult_server = Form.create()(Consult_server);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Consult_server)