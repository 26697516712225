import React from 'react'
import  './index.less'


export  default class Home extends React.Component{
    componentDidMount() {
        let that = this;
      }

    render(){
        return (
            <div  className="home-wrap">
               欢迎使用征牛大数据管理后台
               {/* <div className=""></div> */}
            </div>
            // <div id="g2-bill-view" className="g2-view"></div>
        );
    }
}