import React from 'react'
import './index.less'
import Axios from '../../../axios/index'

import { Table, Divider, Button, Card, message, Popconfirm, Form, Input } from 'antd';
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
const FormItem = Form.Item;
class Withdraw_list extends React.Component {
  state = {
    goto_teacher_add: false,
    goto_teacher_edit: false,
    goto_teacher_edit_path: '',
    withdraw_amount:0,
    data: []
  }
  // 编辑
  reject(index) {
    Axios.ajax({
      url: '/withdraw/reject.php',
      data: {
        token: this.props.userinfo.token,
        withdraw_id: index.id
      }
    }).
      then((res) => {
        message.success("操作成功");


        this.init_data();
      })


  }

  // 删除
  finish(index) {

    Axios.ajax({
      url: '/withdraw/finish.php',
      data: {
        token: this.props.userinfo.token,
        withdraw_id: index.id
      }
    }).
      then((res) => {
        message.success("操作成功");


        this.init_data();
      })

  }

  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();

    this.init_data(data.trade_no);


  }
  componentWillMount() {

    this.init_data();
  }
  // 初始化数据
  init_data(trade_no = '') {
    Axios.ajax({
      url: '/withdraw/list.php',
      data: {
        token: this.props.userinfo.token,
        trade_no: trade_no
      }
    }).
      then((res) => {
        this.setState({
          data: res.data.withdraw_list,
          withdraw_amount:res.data.withdraw_amount
        })
      })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '提现编号',
      dataIndex: 'trade_no',
      align: 'center',
    }, {
      title: '微信昵称',
      dataIndex: 'nickname',
      align: 'center'

    },{
      title: '提现类型',
      dataIndex: 'type',
      align: 'center',
      render: data => <span>{data == 'CARD' ? '银行卡提现' : ''}{data == 'WX+' ? '微信好友转账' : ''}</span>

    },{
      title: '姓名',
      dataIndex: 'real_name',
      align: 'center'

    }, {
      title: '身份证',
      align: 'center',
      dataIndex: 'identify_card',
    }
      , {
      title: '银行卡号',
      align: 'center',
      dataIndex: 'bankcard',
    }
      , {
      title: '银行名称',
      align: 'center',
      dataIndex: 'bankname',
    }
      , {
      title: '提现金额',
      align: 'center',
      dataIndex: 'total_amount',
      render: data => <span>{data}元</span>
    }
      , {
      title: '状态',
      align: 'center',
      dataIndex: 'status',
      render: data => <span>{data == 'WAIT' ? '审核中' : ''}{data == 'SUCCESS' ? '已打款' : ''}{data == 'FAIL' ? '已驳回' : ''}</span>

    }
      , {
      title: '创建时间',
      align: 'center',
      dataIndex: 'create_time',
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <div className="min-width-controll">

          {record.status == 'WAIT' ? <div><Button type="primary" onClick={this.reject.bind(this, record)} >驳回</Button><Divider type="vertical" /><Popconfirm title="是否已打款到该账户？" okText="是" cancelText="否" onConfirm={this.finish.bind(this, record)}><Button type="primary">已打款</Button></Popconfirm></div> : ''}


        </div>
      ),
    }
    ];


    return (

      <Card >
        <div className="form-search-div">
          <Form layout="inline" className="form-login">

            <FormItem label="提现编号">
              {
                getFieldDecorator('trade_no', {

                })(
                  <Input placeholder="请输入提现订单号" className="input-style" style={{width:'300px'}}/>
                )
              }

            </FormItem>

            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style" >搜索</Button>
            </FormItem>
          </Form>
        </div>

        <div className="teacher-header-wrap">
          累计提现金额 : {this.state.withdraw_amount}元
                </div>
        <Table columns={columns} dataSource={this.state.data} className="table-view" />
      </Card>

    );
  }
}

Withdraw_list = Form.create()(Withdraw_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Withdraw_list)