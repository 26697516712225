import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import ReactEcharts from 'echarts-for-react';
import { Table, Divider, Button, Card, message,Icon, Popconfirm, Form, Input, Pagination, Row, Col } from 'antd';
import { Redirect ,NavLink} from 'react-router-dom'
import { getDay } from '../../../utils/index'
import { connect } from "react-redux"

import  { set_current_user_id,set_router_path }  from  './../../../redux/action'
const FormItem = Form.Item;
class User_detail extends React.Component {
  state = {
    data: [],
    subscribe_data:[],
    coupon_data:[],
    study_data:[],
    login_data:[]
  }

  componentWillMount() {
    if(this.props.current_user_id){
      this.init_data();

    }
 
  }
  // 跳转到用户详情
  goto_detail = (data) => {
    // message.info("开发中，敬请期待！")
    const {dispatch}= this.props;
    // dispatch(set_router_path("/user/list"));
    dispatch(set_current_user_id(data));
    this.init_data(data);
   

  }
  goback(){
    window.history.go(-1)
  }
  // 初始化数据
  init_data(user_id=this.props.current_user_id) {
    let that = this;
    let data = {};
    data.token = this.props.userinfo.token;
    data.user_id = user_id;
    Axios.ajax({
      url: '/user/detail.php',
      data: data
    }).
      then((res) => {
        that.setState({
          data: res.data.user_data,
          subscribe_data:res.data.subscribe_data,
          coupon_data:res.data.coupon_record_data,
          login_data:res.data.login_data,
          study_data:res.data.study_data
        })
      })
  }
// 渲染界面
  render() {
    console.log(this.props.current_user_id)
    let data = this.state.data;
    if(!this.props.current_user_id){
      return <Redirect to="/user/list"/>
    }
    // 学习数据
    const study_columns = [
      {
       title: '专栏',
       dataIndex: 'course_title',
       align: 'center'
 
     }, {
       title: '内容',
       dataIndex: 'content_title',
       align: 'center'
 
     }, {
       title: '时间',
       align: 'center',
       dataIndex: 'visit_time',
     }
 
     ];
    // 优惠券数据
    const login_columns = [
      {
       title: 'IP地址',
       dataIndex: 'ip',
       align: 'center'
 
     }, {
       title: '城市',
       dataIndex: 'city',
       align: 'center'
 
     }, {
       title: '设备',
       dataIndex: 'device',
       align: 'center'
     }, {
       title: '登录时间',
       align: 'center',
       dataIndex: 'create_time',
     }
 
     ];
    const subscribe_columns = [
     {
      title: '订阅专栏',
      align: 'center',
      dataIndex: 'title',
    }, {
      title: '订阅时间(月)',
      align: 'center',
      dataIndex: 'expire_day',
    }, {
      title: '实付金额(元)',
      align: 'center',
      dataIndex: 'total_amount',
    }
    , {
      title: '到期时间',
      align: 'center',
      dataIndex: 'expire_time',
    }, {
      title: '创建时间',
      align: 'center',
      dataIndex: 'create_time',
    }
    ];
// 优惠券数据
    const conpon_columns = [
     {
      title: '优惠券类型',
      dataIndex: 'title',
      align: 'center'

    }, {
      title: '优惠金额(元)',
      dataIndex: 'amount',
      align: 'center'

    }, {
      title: '当前状态',
      dataIndex: 'status',
      align: 'center',
      render:(text)=>{
        if(text=="GAIN"){
          return (
            <span className="coupon-font1">已领取</span>
          )
        }else if(text=="DESTRORY"){
          return (
            <span>已核销</span>
          )
        }else if(text=="EXPIRE"){
          return (
            <span >已失效</span>
          )
        }
      }
    }, {
      title: '到期时间',
      align: 'center',
      dataIndex: 'expire_time',
    }, {
      title: '领取时间',
      align: 'center',
      dataIndex: 'create_time',
    }, 

    ];
 
    return (
      <Card className="window-view" title={<div className="window-header-view" >
        <Button type="primary" className="window-close-view"  onClick={this.goback.bind(this)}><Icon type='arrow-left' />返回</Button>
        <div className="window-title-view">员工详情</div>
      </div>}>
        <div className="addform-view" >
        <Card title="用户信息" className="card-content-view">

          <Row justify="center" align="middle" >
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  用户昵称：
                </Col>
                <Col span={16} className="user-row-value-view">
                {data.nickname}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  用户头像：
                </Col>
                <Col span={16} className="user-row-value-view">
                  <img src={data.headimage} className="user-headimage-pic" />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  手机号：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.mobile}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  注册时间：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.create_time}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* 钱包相关信息 */}
          <Row justify="center" align="middle" >
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  累计推广金额：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.promot_amount}元
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  可提现余额：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.promot_amount - data.withdraw_amount-data.use_balance}元
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  累计消费金额：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.custom_amount}元
                </Col>
              </Row>
            </Col>

            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  累计提现金额：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.withdraw_amount}元
                </Col>
              </Row>
            </Col>

          </Row>

          <Row justify="center" align="middle" >
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  收货人姓名：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.address_nickname}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                收货手机号：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.address_phone}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                收货地址：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.address_detail}
                </Col>
              </Row>
            </Col>
            
          <Col span={6}>
          </Col>
          </Row>

          <Row justify="center" align="middle" >
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  真实姓名：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.real_name}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  身份证号：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.identify_card}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  开户银行：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.bankname}
                </Col>
              </Row>
            </Col>
            
          <Col span={6}>
            <Row className="user-row-view" >
              <Col span={8} className="user-row-label-view">
                银行卡号：
                </Col>
              <Col span={16} className="user-row-value-view">
                {data.bankcard}
                </Col>
            </Row>
          </Col>
          </Row>

          <Row justify="center" align="middle" >
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                 父级用户：
                </Col>
                <Col span={16} className="user-row-value-view">
                 {data.parent_name?<NavLink to="/user/detail"><span className="coupon-font1" onClick={this.goto_detail.bind(this,data.parent_id)}>{data.parent_name}</span></NavLink>:'暂无'}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
             
                </Col>
                <Col span={16} className="user-row-value-view">

                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
  
                </Col>
                <Col span={16} className="user-row-value-view">
     
                </Col>
              </Row>
            </Col>
            
          <Col span={6}>
            <Row className="user-row-view" >
              <Col span={8} className="user-row-label-view">
                
                </Col>
              <Col span={16} className="user-row-value-view">

                </Col>
            </Row>
          </Col>
            
          </Row>
        </Card>
        <Card title="学习日志" className="card-content-view-down">

        <Table columns={study_columns} dataSource={this.state.study_data} className="table-view" />
        
        </Card>
        <Card title="登录日志" className="card-content-view-down">

        <Table columns={login_columns} dataSource={this.state.login_data} className="table-view" />
        
        </Card>

        <Card title="优惠券信息" className="card-content-view-down">

        <Table columns={conpon_columns} dataSource={this.state.coupon_data} className="table-view" />
        
        </Card>
        <Card title="订阅信息" className="card-content-view-down">

        <Table columns={subscribe_columns} dataSource={this.state.subscribe_data} className="table-view" />
        
        </Card>

      </div >
      </Card>
    );
  }
}

User_detail = Form.create()(User_detail);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    current_user_id: state.current_user_id
  }
};
export default connect(mapStateToProps)(User_detail)