import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import ReactEcharts from 'echarts-for-react';
import { Table, Divider, Button, Card, Modal, Popconfirm, Form, Input, Pagination, message,Select } from 'antd';
import { Redirect } from 'react-router-dom'
import { getDay } from '../../../utils/index'
import QRCode from 'qrcode.react';
import { SERVER_ROOT } from '../../../config/config'
import { NavLink } from 'react-router-dom'
import { set_current_user_id, set_router_path } from './../../../redux/action'
import { connect } from "react-redux"
import * as ACTION from '../../../redux/action/'


const FormItem = Form.Item;
const { Option } = Select;
class Comment_list extends React.Component {
  state = {
    goto_teacher_add: false,
    goto_teacher_edit: false,
    qrcode_visible: false,
    remark_visible:false,
    goto_teacher_edit_path: '',
    current_page:1,
    remark:'',
    remark_key:'',
    data: [],
    user_id:'',
    current_page:1,
    total_page:1,
    qrcode_url: '',
    user_amount: 0
  }
  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    this.setState({
      current_page:1
    })
    const { dispatch } = this.props;
    dispatch(ACTION.set_current_page(1));
    this.init_data(data.status);
  }
  componentWillMount() {
    let that=this
    this.setState({
      current_page:that.props.current_page
    })
    that.init_data('');
  }
   // 监听页面切换
 change_page = page => {
  let that=this;
  this.setState({
    current_page: page,
  });
  const { dispatch } = this.props;
    dispatch(ACTION.set_current_page(this.state.current_page));
}
  // 初始化数据
  init_data(status = '') {
    let that = this;

    Axios.ajax({
      url: '/comment/list.php',
      data: {
        token: this.props.userinfo.token,
        status: status
      }
    }).
      then((res) => {

        this.setState({
          data: res.data
        })
      })
  }

  // 跳转到用户详情
  goto_detail = (data) => {
    // message.info("开发中，敬请期待！")
    const { dispatch } = this.props;
    // dispatch(set_router_path("/user/list"));
    dispatch(set_current_user_id(data.id));
    // 设置分页
    // console.log(this.state.current_page)
    

  }
  // 隐藏二维码
  hiden_qrcode = () => {
    this.setState({
      qrcode_url: '',
      qrcode_visible: false
    })
  }
  // 隐藏备注二维码
  hidden_remark = () => {
    this.setState({
      remark_visible: false
    })
  }
  // 提交备注修改
  submit_remark=()=>{
     let that=this;
     let data={};
     data.token=this.props.userinfo.token;
     data.user_id=this.state.user_id;
     data.remark=this.state.remark;
     if(this.state.remark==''){
       message.info("备注不能为空哦!");
       return false;
     }

     Axios.ajax({
      url: '/user/modify_remark.php',
      data: data
    }).
      then((res) => {
          message.success("保存成功");
          this.setState({
            remark_visible: false
          })
          that.init_data();
      })
  
  }

  // 生成分销链接与二维码
  produce_code(data) {
    let that = this;
    let qrcode_url=SERVER_ROOT + "mini_api/h5_gate.php?s="+data.id ;
    that.setState({
      qrcode_url: qrcode_url,
      qrcode_visible: true
    })
  }
  
  // 修改备注
  modify_remark(data){
    let that=this;

    that.setState({
      user_id:data.id,
      remark:data.remark,
      remark_visible:true
    })
  }

  deal(record,type){
    let that=this;
    let id=record.id;
    let data={};
    data.token=this.props.userinfo.token;
    data.comment_id=id;
    data.type=type;
    Axios.ajax({
     url: '/comment/deal.php',
     data: data
   }).
     then((res) => {
         message.success("操作成功");
         that.init_data();
     })
    
  }
  // 修改备注
modify_wuliu_remark(data){
  let that=this;

  that.setState({
    order_id:data.id,
    wuliu_remark:data.wuliu_remark,
    wuliu_remark_visible:true
  })

}
// 隐藏备注二维码
hidden_wuliu_remark = () => {
  this.setState({
    wuliu_remark_visible: false
  })
}
 // 修改用户备注
 change_wuliu_remark(e){
  const { value } = e.target;

  this.setState({
    wuliu_remark: value
  })
}

// 提交备注修改
submit_wuliu_remark=()=>{
  let that=this;
  let data={};
  data.token=this.props.userinfo.token;
  data.order_id=this.state.order_id;
  data.wuliu_remark=this.state.wuliu_remark;


  Axios.ajax({
   url: '/wuliu/comment_wuliu_remark.php',
   data: data
 }).
   then((res) => {
       message.success("保存成功");
       that.hidden_wuliu_remark()
       that.init_data();
   })

}

// 跳转到用户详情
goto_user_detail = (data) => {
  // message.info("开发中，敬请期待！")
  const {dispatch}= this.props;
  dispatch(set_current_user_id(data.user_id));
  dispatch(ACTION.set_current_page(this.state.current_page));
  // dispatch(set_router_path("/user/list"));
}
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    }, {
      title: '昵称',
      dataIndex: 'nickname',
      align: 'center',
      render:(text,data)=>{
        return (<NavLink to="/user/detail"><span style={{color:'green'}} onClick={this.goto_user_detail.bind(this, data)}>{text}</span></NavLink>)
     }
    },  {
      title: '头像',
      dataIndex: 'headimage',
      align: 'center',
      render: data => (
        <img src={data} className="headimage-pic" />
      )

    }, {
      title: '内容',
      align: 'center',
      dataIndex: 'content',
      render: text => {
        return <div className="comment-content-view">{text}</div>
      }
    }, {
      title: '状态',
      align: 'center',
      dataIndex: 'status',
      render: text => {
        if(text==0){
          return <span>待处理</span>
        }else if(text==1){
          return <span className="primary-font1">显示</span>
        }else if(text==2){
          return <span className="primary-font2">隐藏</span>
        }
      }
       
    }
      ,{
        title: '物流备注',
        align: 'center',
        dataIndex: 'wuliu_remark'
      }
    , {
      title: '创建时间',
      align: 'center',
      dataIndex: 'create_time',
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <div >
          {record.status==0||record.status==2?<Popconfirm title="确认显示该评论？" okText="是" cancelText="否" onConfirm={this.deal.bind(this,record,"SUCCESS")}>
             <Button type="primary"  className="button-padding">显示</Button>
           </Popconfirm>:""}
          {record.status==0?<Divider type="vertical " />:""}
          {record.status==0 ||record.status==1?<Popconfirm title="确认拒绝该评论？" okText="是" cancelText="否" onConfirm={this.deal.bind(this,record,"FAIL")}>
             <Button type="primary"  className="button-padding">隐藏</Button>
           </Popconfirm>:""}
           <Divider type="vertical " />
           <Button type="primary" onClick={this.modify_wuliu_remark.bind(this, record)} >物流</Button>
        </div>
      ),
    }
    ];


    return (

      <div>

        <div className="form-search-div">
          <Form layout="inline" className="form-login">

            <FormItem label="评论状态">
              {
                getFieldDecorator('status', {

                })(
                  <Select
                  showSearch
                  className="input-style"
                  placeholder="请选择评论状态"
                  optionFilterProp="children"
                  style={{width:"300px"}}
        
                >
                    <Option value="0">全部</Option>
                    <Option value="1">显示</Option>
                    <Option value="2">隐藏</Option>
                    <Option value="3">待处理</Option>
               
                </Select>
                )
              }
            </FormItem>
            
            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style">搜索</Button>
            </FormItem>
          </Form>
        </div>


        <Table columns={columns} dataSource={this.state.data} className="table-view" pagination={{
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:50,
        }}/>
        <Modal
            title="修改物流备注"
            visible={this.state.wuliu_remark_visible}
            onOk={this.submit_wuliu_remark}
            onCancel={this.hidden_wuliu_remark}
            okText="保存"
            cancelText="取消"
          >
            <Input placeholder="请输入物流备注" value={this.state.wuliu_remark}  onChange={this.change_wuliu_remark.bind(this)} style={{ width: '100%' }} />

          </Modal>

      </div>

    );
  }
}

Comment_list = Form.create()(Comment_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    current_page:state.current_page
  }
};
export default connect(mapStateToProps)(Comment_list)