import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import ReactEcharts from 'echarts-for-react';
import { Table, Divider, Button, Card, Modal, Popconfirm, Form, Input, Pagination, message } from 'antd';
import { Redirect } from 'react-router-dom'
import { getDay } from '../../../utils/index'
import QRCode from 'qrcode.react';
import { SERVER_ROOT } from '../../../config/config'
import { NavLink } from 'react-router-dom'
import { set_current_user_id, set_router_path } from './../../../redux/action'
import { connect } from "react-redux"
import * as ACTION from '../../../redux/action/'
const FormItem = Form.Item;
class User_list extends React.Component {
  state = {
    goto_teacher_add: false,
    goto_teacher_edit: false,
    qrcode_visible: false,
    remark_visible:false,
    remark:'',
    goto_teacher_edit_path: '',
    
    remark_key:'',
    data: [],
    user_id:'',
    current_page:1,
    total_page:1,
    qrcode_url: '',
    user_amount: 0
  }
  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    this.setState({
      current_page:1
    })
    this.init_data(data.mobile, data.nickname,data.remark_key,1);
  }
  componentWillMount() {
    let that=this
    this.setState({
      current_page:that.props.current_page
    })
    that.init_data('', '', '',that.props.current_page);
  }
  // 监听页面切换
  change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
    let data = this.props.form.getFieldsValue();
    that.init_data(data.mobile, data.nickname,data.remark_key,page);
  }
  // 初始化数据
  init_data(mobile = '', nickname = '',remark_key='', page = this.state.current_page) {
    let that = this;

    Axios.ajax({
      url: '/user/list.php',
      data: {
        token: this.props.userinfo.token,
        mobile: mobile,
        page: page,
        nickname: nickname,
        remark_key:remark_key
      }
    }).
      then((res) => {

        this.setState({
          data: res.data.list,
          user_amount: res.data.count,
          total_page: res.data.count
        })
      })
  }

  // 跳转到用户详情
  goto_detail = (data) => {
    // message.info("开发中，敬请期待！")
    const { dispatch } = this.props;
    // dispatch(set_router_path("/user/list"));
    dispatch(set_current_user_id(data.id));
    // 设置分页
    // console.log(this.state.current_page)
    dispatch(ACTION.set_current_page(this.state.current_page));

  }
  // 隐藏二维码
  hiden_qrcode = () => {
    this.setState({
      qrcode_url: '',
      qrcode_visible: false
    })
  }
  // 隐藏备注二维码
  hidden_remark = () => {
    this.setState({
      remark_visible: false
    })
  }
  // 提交备注修改
  submit_remark=()=>{
     let that=this;
     let data={};
     data.token=this.props.userinfo.token;
     data.user_id=this.state.user_id;
     data.remark=this.state.remark;
     if(this.state.remark==''){
       message.info("备注不能为空哦!");
       return false;
     }

     Axios.ajax({
      url: '/user/modify_remark.php',
      data: data
    }).
      then((res) => {
          message.success("保存成功");
          this.setState({
            remark_visible: false
          })
          that.init_data();
      })
  
  }

  // 生成分销链接与二维码
  produce_code(data) {
    let that = this;
    let qrcode_url=SERVER_ROOT + "mini_api/h5_gate.php?s="+data.id ;
    that.setState({
      qrcode_url: qrcode_url,
      qrcode_visible: true
    })
  }
  
  // 修改备注
  modify_remark(data){
    let that=this;

    that.setState({
      user_id:data.id,
      remark:data.remark,
      remark_visible:true
    })
  }
  // 加入黑名单
  join_blacklist(record){
    let that=this;
    let id=record.id;
    let data={};
    data.token=this.props.userinfo.token;
    data.id=id;
    data.type=2;
    Axios.ajax({
     url: '/user/blacklist.php',
     data: data
   }).
     then((res) => {
         message.success("操作成功");
         that.init_data();
     })
    
  }
  // 移除黑名单
  delete_blacklist(record){
    let that=this;
    let id=record.id;
    // console.log(id)
    let data={};
    data.token=this.props.userinfo.token;
    data.id=id;
    data.type=1;
    Axios.ajax({
     url: '/user/blacklist.php',
     data: data
   }).
     then((res) => {
         message.success("操作成功");
         that.init_data();
     })
  }
  // 开启分享优惠
  begin_share_benefit(record){
    let that=this;
    let id=record.id;
    // console.log(id)
    let data={};
    data.token=this.props.userinfo.token;
    data.id=id;
    data.type="BEGIN";
    Axios.ajax({
     url: '/user/share_benefit.php',
     data: data
   }).
     then((res) => {
         message.success("操作成功");
         that.init_data();
     })
  }
  // 关闭分享优惠
  end_share_benefit(record){
    let that=this;
    let id=record.id;
    // console.log(id)
    let data={};
    data.token=this.props.userinfo.token;
    data.id=id;
    data.type="END";
    Axios.ajax({
     url: '/user/share_benefit.php',
     data: data
   }).
     then((res) => {
         message.success("操作成功");
         that.init_data();
     })
  }
  // 修改用户备注
  change_remark(e){
    const { value } = e.target;

    this.setState({
      remark: value
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '用户编号',
      dataIndex: 'id',
      align: 'center',
    }, {
      title: '用户昵称',
      dataIndex: 'nickname',
      align: 'center'

    }, {
      title: '备注',
      align: 'center',
      dataIndex: 'remark',
    }, {
      title: '用户头像',
      dataIndex: 'headimage',
      align: 'center',
      render: data => (
        <img src={data} className="headimage-pic" />
      )

    }, {
      title: '用户手机号',
      align: 'center',
      dataIndex: 'mobile',
    }


      , {
      title: '时间',
      align: 'center',
      dataIndex: 'create_time',
      render: (text,record) => {
        if(record.subscript_status==0){
        return <text>{text} <span >（ 未订阅 ）</span></text>
        }else if(record.subscript_status==1){
          return <text className="primary-font1">{record.subscript_time} <span >（ 正常 ）</span></text>
        }else if(record.subscript_status==2){
          return <text className="primary-font2">{record.subscript_time} <span >（ 过期 ）</span></text>
        }
      }

    }
    , {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <div className="min-width-controll">

          <NavLink to="/user/detail">  <Button type="primary" onClick={this.goto_detail.bind(this, record)} className="button-padding" >详情</Button></NavLink>
          <Divider type="vertical" />
          <Button type="primary" onClick={this.produce_code.bind(this, record)} className="button-padding">分销</Button>
          <Divider type="vertical" />
          <Button type="primary" onClick={this.modify_remark.bind(this, record)} className="button-padding">备注</Button>
          <Divider type="vertical " />
          {record.share_benefit==1?<Popconfirm title="确认关闭该用户的分享优惠？" okText="是" cancelText="否" onConfirm={this.end_share_benefit.bind(this,record)}>
             <Button type="primary"  className="button-padding">关闭分享优惠</Button>
           </Popconfirm>:<Popconfirm title="确认开启该用户的分享优惠？" okText="是" cancelText="否" onConfirm={this.begin_share_benefit.bind(this,record)}>
             <Button type="primary" className="button-padding" >开启分享优惠</Button>
           </Popconfirm>}
          <Divider type="vertical " />
          {record.is_blacklist==1?<Popconfirm title="确认将该用户加入黑名单？" okText="是" cancelText="否" onConfirm={this.join_blacklist.bind(this,record)}>
             <Button type="primary"  className="button-padding">加黑名单</Button>
           </Popconfirm>:<Popconfirm title="确认将该用户解除黑名单？" okText="是" cancelText="否" onConfirm={this.delete_blacklist.bind(this,record)}>
             <Button type="primary" className="button-padding" >删黑名单</Button>
           </Popconfirm>}
          
          
        </div>
      ),
    }
    ];


    return (

      <div>

        <div className="form-search-div">
          <Form layout="inline" className="form-login">

            <FormItem label="用户手机号">
              {
                getFieldDecorator('mobile', {

                })(
                  <Input placeholder="请输入用户手机" className="input-style" onPressEnter={this.handleSubmit} />
                )
              }

            </FormItem>
            <FormItem label="用户昵称">
              {
                getFieldDecorator('nickname', {

                })(
                  <Input placeholder="请输入用户昵称" className="input-style" style={{ width: '300px' }} onPressEnter={this.handleSubmit} />
                )
              }
            </FormItem>
            <FormItem label="用户备注">
              {
                getFieldDecorator('remark_key', {

                })(
                  <Input placeholder="请输入用户备注" className="input-style" style={{ width: '300px' }} onPressEnter={this.handleSubmit} />
                )
              }
            </FormItem>
            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style">搜索</Button>
            </FormItem>
          </Form>
        </div>

        <div className="teacher-header-wrap">
          当前用户总数 : {this.state.user_amount}人
                </div>
        <Table columns={columns} dataSource={this.state.data} className="table-view" pagination={{
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:50,
          total: this.state.total_page

        }} />
        {/* 二维码界面 */}
        <Modal
          title="生成分销渠道链接与二维码"
          visible={this.state.qrcode_visible}
          onOk={this.hiden_qrcode}
          onCancel={this.hiden_qrcode}
          okText="确定"
          cancelText="取消"
        >
          <div className="qrcode-window-view">
            <text className="qrcode-font1" style={{ marginBottom: '20px' }}>分销链接：{this.state.qrcode_url}</text>
            {/* <text className="qrcode-font1" style={{ marginBottom: '0.6rem' }}>即可领取优惠券</text> */}
            <QRCode value={this.state.qrcode_url} />
          </div>
        </Modal>

        {/* 库存界面 */}
        <Modal
            title="修改用户备注"
            visible={this.state.remark_visible}
            onOk={this.submit_remark}
            onCancel={this.hidden_remark}
            okText="保存"
            cancelText="取消"
          >
            <Input placeholder="请输入用户备注" value={this.state.remark}  onChange={this.change_remark.bind(this)} style={{ width: '300px' }} />

          </Modal>
      </div>

    );
  }
}

User_list = Form.create()(User_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    current_page:state.current_page
  }
};
export default connect(mapStateToProps)(User_list)