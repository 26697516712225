import React from 'react'
import './index.less'
import { connect } from "react-redux"
import Axios from '../../../axios/index'
import { Redirect,NavLink} from 'react-router-dom'
import { get_new_filename } from '../../../utils/index'
import { getBase64, uploadImageCallBack } from '../../../utils/index'
import { client } from '../../../utils/oss'
import { Modal } from 'antd'
import { Radio } from 'antd';
import { Editor } from 'react-draft-wysiwyg'
import draftjs from 'draftjs-to-html'
import { OSS_VIDEO_PATH, ROOT_PATH } from '../../../config/config'
import {
  Form, Input, Icon, Upload, Switch,Select, message, Button, InputNumber, Card, Progress
} from 'antd';
const Option = Select.Option;
const { TextArea } = Input;


function handleChange(value) {
  console.log(`selected ${value}`);
}
class Video_add extends React.Component {
  state = {
    loading: false,
    is_uploading: false,
    upload_percent: 0,
    goto_video_list: false,
    tag_visible:false,
    tag_name:'',
    tag_list:[],
    goto_course_list: false,
    is_free: true,
    type: 'VIDEO',
    form_item_style: 'form-item-width-custom',
    video_url: null
  };

  // 提交表单
  handleSubmit = (e) => {
    let data = this.props.form.getFieldsValue();
    let that = this;
    data.course_id = this.props.course_id;
    data.token = this.props.userinfo.token;
    data.type = this.state.type;
    if (this.state.is_free) {
      data.is_free = 0;
    } else {
      data.is_free = 1;
    }
    

    this.props.form.validateFields((err, values) => {
      if (!err) {
        data.content = draftjs(data.content);
        if(data.type=='VIDEO'&&!data.video_url){
          message.info(`视频内容不能为空`);
          return false;
        }
        if(data.type=='ARTICLE'&&!data.content){
          message.info(`图文内容不能为空`);
          return false;
        }
        Axios.ajax({
          url: '/video/add.php',
          data: data
        }).
          then((res) => {
            message.success(`新增内容成功！`)
            that.setState({
              goto_video_list: true
            })

          })
      }
    });
  }
  componentWillMount() {
    this.init_tag_data();
  }
  // 切换radio
  change_radio = (e) => {
    this.setState({
      type: e.target.value
    })
    if (e.target.value == 'VIDEO') {
      this.setState({
        form_item_style: 'form-item-width-custom'
      })
    } else {
      this.setState({
        form_item_style: 'form-item-widthall'
      })
    }
  }

  // 上传视频
  beforeUploadVideo = (file) => {
    let that = this;
    let new_filename = get_new_filename(file.name);
    client.multipartUpload(OSS_VIDEO_PATH + new_filename, file, {
      progress: function (percentage, checkpoint, res) {

        that.setState({
          upload_percent: Math.floor(percentage * 100),
          is_uploading: true
        })

      }
    })
      .then(function (result) {
        message.success("上传视频成功");
        that.setState({
          is_uploading: false,
          video_url: ROOT_PATH + OSS_VIDEO_PATH + new_filename
        })
        that.props.form.setFieldsValue({
          video_url: ROOT_PATH + OSS_VIDEO_PATH + new_filename
        });
      })
      .catch(function (err) {
        Modal.info({
          title: "提示",
          content: err.message
        })
      });
    return false;
  }
  onSwitchChange = (checked) => {
    this.setState({
      is_free: checked
    })
  }
  onEditorChange = (editorContent) => {

    this.setState({
      editorContent,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    });
  };
  // 添加标签
  show_tag(){
    let that=this

    this.setState({
      tag_visible:true,
      tag_name: ''
    })
  }
  // 隐藏标签窗口
  hidden_tag(){
    let that=this
    this.setState({
      tag_visible:false,
      tag_name: ''
    })
  }
  change_tag_name(e){
    let that=this
    const { value } = e.target;

    this.setState({
      tag_name: value
    })
  }
  submit_tag(){
    let that=this
    let tag_name=that.state.tag_name
    if(tag_name==''){
        message.error('标签名称不能为空！');
        return;
    }
    Axios.ajax({
      url: '/tag/add.php',
      data: {
        token: this.props.userinfo.token,
        name:tag_name
      }
    }).
      then((res) => {
        that.init_tag_data();
        that.hidden_tag()
      })
  }
  // 初始化数据
  init_tag_data() {
    Axios.ajax({
      url: '/tag/list.php',
      data: {
        token: this.props.userinfo.token
      }
    }).
      then((res) => {
        this.setState({
          tag_list: res.data
        })
      })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { editorContent, editorState } = this.state;
    let form_item_style = this.state.form_item_style;
    let that=this;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    if (this.state.goto_course_list||this.props.course_id==null) {
      return (
        <Redirect to="/course/list" />
      );
    }
    if (this.state.goto_video_list) {
      return (
        <Redirect to="/video/list" />
      );
    }
    // 上传组件
    let upload_view = (
      <Form.Item label="上传视频" className={form_item_style}>
        <div className="flex-row-item">
          {
            this.state.is_uploading ? <Progress type="circle" percent={this.state.upload_percent} style={{ marginRight: 30, width: 100 }} /> : ''

          }
          {
            this.state.is_uploading ? '' : getFieldDecorator('video_url', {
           
            })(
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={this.beforeUploadVideo}
                onChange={this.handleChange}>
                {this.state.video_url ? <video src={this.state.video_url} width="300" height="auto" controls /> : uploadButton}
              </Upload>
            )
          }
        </div>
      </Form.Item>
    )
    //富文本组件
    let editor_view = (
      <Form.Item
        label="图文内容" className={form_item_style}
      >
        {getFieldDecorator('content', {
     
        })(
          <Editor
            editorState={editorState}
            onContentStateChange={this.onEditorChange}
            onEditorStateChange={this.onEditorStateChange}
            editorClassName="video-content-wrapper"
            toolbar={{
              image: {
                uploadCallback: uploadImageCallBack,
                previewImage: true,
              }
            }}
          />
        )}
      </Form.Item>
    )
    return (

      <Card  className="window-view " title={<div className="window-header-view" >
      <NavLink to="/video/list"><Button type="primary" className="window-close-view"><Icon type='arrow-left' />返回</Button></NavLink>
      <div className="window-title-view">新增内容</div>
    </div>}>
    <div className="video-content-wrap">

        <Form className="course-form-video-add" >
          <Form.Item
            label="课程序号" className={form_item_style}
          >
            {getFieldDecorator('index', {
              rules: [{
                required: true, message: '课程序号不能为空',
              }],

            })(
              <InputNumber min={1} max={100} placeholder="请输入1~100之间的数组，1为最高级" style={{ width: 300 }} />
            )}
          </Form.Item>
          <Form.Item
            label="内容标题" className={form_item_style}
          >
            {getFieldDecorator('title', {
              rules: [{
                required: true, message: '标题不能为空',
              }],
            })(
              <Input placeholder="请输入视频标题" className="course-input-style" />
            )}
          </Form.Item>
          <Form.Item
            label="标签" className={form_item_style}
          >
             {getFieldDecorator('tag_id', {

              })(

                <Select
                  mode="multiple"
                  placeholder="请选择标签"
                  onChange={handleChange}
                  className="course-input-style"
                >
                  {
                    this.state.tag_list.map((item) => {
                      return <Option key={item.id} >{item.name}</Option>
                    })
                  }
                </Select>

              )}
              <Button type="primary" onClick={that.show_tag.bind(this)} style={{marginLeft:"20px"}}>新增标签</Button>
          </Form.Item>
  

          <Form.Item
            label="是否需订阅" className={form_item_style}
          >
            {getFieldDecorator('is_free', {

            })(
              <Switch checkedChildren="需订阅" unCheckedChildren="试看" onChange={this.onSwitchChange} defaultChecked />

            )}
          </Form.Item>

          <Form.Item
            label="内容类型" className={form_item_style}
          >
            <Radio.Group defaultValue="VIDEO" buttonStyle="solid" onChange={this.change_radio} >
              <Radio.Button value="ARTICLE" >图文</Radio.Button>
              <Radio.Button value="VIDEO">视/音频</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {this.state.type == 'VIDEO' ? upload_view : editor_view}
          <Form.Item className="layout-center">
            <Button type="primary" onClick={this.handleSubmit}>提交</Button>
          </Form.Item>

        </Form>
        <Modal
            title="新增标签"
            visible={this.state.tag_visible}
            onOk={this.submit_tag.bind(that)}
            onCancel={this.hidden_tag.bind(that)}
            okText="提交"
            cancelText="取消"
          >
            <Input placeholder="请输入专栏标签，不多于15字" value={this.state.tag_name}  onChange={that.change_tag_name.bind(this)} style={{ width: '300px' }} />

          </Modal>
          </div>
      </Card>
    );
  }
}

Video_add = Form.create()(Video_add);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    course_id: state.course_id
  }
};
export default connect(mapStateToProps)(Video_add)