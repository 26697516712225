import OSS from "ali-oss"
import { Modal } from 'antd'

// 测试环境
// let client = new OSS({
//     region: 'oss-cn-hangzhou',
//     accessKeyId: 'LTAI6RDy5ysnDteq',
//     accessKeySecret: 'fcZiZZPxv2rvFFRkgNv6rOVQcKiFEM',
//     bucket: 'begin-educate'
// });
// 正式环境
let client = new OSS({
    region: 'oss-cn-shanghai',
    accessKeyId: 'LTAI4FbfyGTGJ8ysHdjQcKWL',
    accessKeySecret: 'lpg0I7GJAUcanSnykAqB3H6ueoqXx1',
    bucket: 'zndsj-admin'
});

export { client }

