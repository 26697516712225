

const OSS_VIDEO_PATH="educate_video/";
const OSS_IMAGE_PATH="educate_image/";
// 测试服
// const  ROOT_PATH="https://www.educate.simpleoj.cn/";
// const  SERVER_PATH="https://api.educate.simpleoj.cn/server/";
// const  SERVER_ROOT="https://api.educate.simpleoj.cn/";
// 正式服
const  ROOT_PATH="https://admin.dsjchaogu.com/";
const  SERVER_PATH="https://api.dsjchaogu.com/server/";
const  SERVER_ROOT="https://api.dsjchaogu.com/";

 export {OSS_VIDEO_PATH,OSS_IMAGE_PATH,ROOT_PATH,SERVER_PATH,SERVER_ROOT};

