import React from 'react'
import './index.less'
import Axios from '../../../axios/index'

import  { set_current_user_id ,set_router_path}  from  './../../../redux/action'
import { NavLink } from 'react-router-dom'
import { Table, Divider, Button, Card, message, InputNumber, Form, Input, Modal,Select } from 'antd';
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import * as ACTION from '../../../redux/action/'
const FormItem = Form.Item;
const { Option } = Select;
class Subscribe_list extends React.Component {
  state = {
    goto_teacher_add: false,
    goto_teacher_edit: false,
    visible: false,
    remark_visible:false,
    remark:'',
    current_page:1,
    goto_teacher_edit_path: '',
    send_day: '',
    current_page:1,
    current_id: '',
    withdraw_amount: 0,
    data: []
  }
  // 赠送时长
  send_time(data) {
    this.setState({
      current_id: data.id,
      visible: true
    })
  }
  // 监听赠送时长
  change_send_day(v) {
    this.setState({
      send_day: v
    })
  }
  // 提交赠送时长
  handle_send_day=()=> {
    let that = this;
    let data = {};
    data.token =  this.props.userinfo.token;
    data.subscribe_id = that.state.current_id;
    data.send_day=that.state.send_day;

    Axios.ajax({
      url: '/subscribe/give.php',
      data: data
    }).
      then((res) => {
        message.success("赠送成功");
        that.setState({
          visible:false,
          send_day:''
        })
        let data = that.props.form.getFieldsValue();
          this.init_data(data.nickname,data.status,data.remark,data.user_type,that.state.current_page);
      })
  }
  // 取消赠送时长
  handleCancel = () => {
    this.setState({
      visible: false,
      send_day: ''
    })
  }
  handleSubmit = () => {
    let that=this
    let data = this.props.form.getFieldsValue();
    that.setState({
      current_page:1
    })
    const { dispatch } = this.props;
    dispatch(ACTION.set_current_page(1));
    // let data = this.props.form.getFieldsValue();
    this.init_data(data.nickname,data.status,data.remark,data.user_type,1);


  }
  componentWillMount() {
    this.setState({
      current_page:this.props.current_page
    })
    this.init_data();
  }
  // 初始化数据
  init_data(nickname = '',status = '',remark = '',user_type='',page=this.state.current_page) {
    console.log(remark)
    Axios.ajax({
      url: '/subscribe/list.php',
      data: {
        token: this.props.userinfo.token,
        nickname: nickname,
        remark:remark,
        user_type:user_type,
        status:status
      }
    }).
      then((res) => {
        this.setState({
          data: res.data
        })
      })
  }
  // 跳转到用户详情
  goto_detail = (data) => {
    // message.info("开发中，敬请期待！")
    const {dispatch}= this.props;
    dispatch(set_current_user_id(data.user_id));
    dispatch(ACTION.set_current_page(this.state.current_page));
    // dispatch(set_router_path("/user/list"));
  }
   // 监听页面切换
   change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
    // 设置分页
    // console.log(this.state.current_page)
    const { dispatch } = this.props;
    dispatch(ACTION.set_current_page(this.state.current_page));

  }
  // 修改备注
  modify_remark(data){
    let that=this;

    that.setState({
      user_id:data.user_id,
      remark:data.remark,
      remark_visible:true
    })
  }
  // 隐藏备注二维码
  hidden_remark = () => {
    this.setState({
      remark_visible: false
    })
  }
  // 提交备注修改
  submit_remark=()=>{
     let that=this;
     let data={};
     data.token=this.props.userinfo.token;
     data.user_id=this.state.user_id;
     data.remark=this.state.remark;
     if(this.state.remark==''){
       message.info("备注不能为空哦!");
       return false;
     }

     Axios.ajax({
      url: '/user/modify_remark.php',
      data: data
    }).
      then((res) => {
          message.success("保存成功");
          this.setState({
            remark_visible: false
          })
          let data = that.props.form.getFieldsValue();
          this.init_data(data.nickname,data.status,data.remark,data.user_type,that.state.current_page);
   
      })
  
  }
  // 修改用户备注
  change_remark(e){
    const { value } = e.target;

    this.setState({
      remark: value
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    let that = this;
    const columns = [{
      title: '订阅编号',
      align: 'center',
      dataIndex: 'id',
    },{
      title: '订阅用户',
      dataIndex: 'nickname',
      align: 'center',
      render:(text,data)=>{
         return (<NavLink to="/user/detail"><span style={{color:'green'}} onClick={this.goto_detail.bind(this, data)}>{text}</span></NavLink>)
      }
    },{
      title: '备注',
      align: 'center',
      dataIndex: 'remark',
    },  {
      title: '订阅专栏',
      align: 'center',
      dataIndex: 'title',
    }, {
      title: '订阅时间(月)',
      align: 'center',
      dataIndex: 'expire_day',
    }
    , {
      title: '实付金额(元)',
      align: 'center',
      dataIndex: 'total_amount',
    }
    , {
      title: '到期时间',
      align: 'center',
      dataIndex: 'expire_time',
    }, {
      title: '创建时间',
      align: 'center',
      dataIndex: 'create_time',
    },{
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <div className="min-width-controll">
          <Button type="primary" onClick={this.send_time.bind(this, record)} >赠送订阅时长</Button>
          <Divider type="vertical " />
          <Button type="primary" onClick={this.modify_remark.bind(this, record)} className="button-padding">备注</Button>
          
        </div>
      ),
    }
    ];


    return (

      <Card >
        <div className="form-search-div">
          <Form layout="inline" className="form-login">

            <FormItem label="用户昵称">
              {
                getFieldDecorator('nickname', {

                })(
                  <Input placeholder="请输入用户昵称" className="input-style" style={{ width: '300px' }} onPressEnter={this.handleSubmit} />
                )
              }

            </FormItem>
            <FormItem label="用户备注">
              {
                getFieldDecorator('remark', {

                })(
                  <Input placeholder="请输入用户备注" className="input-style" style={{ width: '300px' }} onPressEnter={this.handleSubmit} />
                )
              }
            </FormItem>
            <FormItem label="用户类型">
              {
                getFieldDecorator('user_type', {

                })(
                  <Select
                  showSearch
                  className="input-style"
                  placeholder="请选择用户类型"
                  optionFilterProp="children"
                  style={{width:"300px"}}
        
                >
                    <Option value="0">全部</Option>
                    <Option value="1">正式</Option>
                    <Option value="2">试用</Option>
               
                </Select>
                )
              }
            </FormItem>
            <FormItem label="订阅状态">
              {
                getFieldDecorator('status', {

                })(
                  <Select
                  showSearch
                  className="input-style"
                  placeholder="请选择订阅状态"
                  optionFilterProp="children"
                  style={{width:"300px"}}
        
                >
                    <Option value="0">全部</Option>
                    <Option value="1">未过期</Option>
                    <Option value="2">已到期</Option>
               
                </Select>
                )
              }
            </FormItem>

            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style" >搜索</Button>
            </FormItem>
          </Form>
        </div>
        <Modal
          title="赠送订阅时长"
          visible={this.state.visible}
          onOk={this.handle_send_day}
          onCancel={this.handleCancel}
          okText="提交"
          cancelText="取消"
        >
          <InputNumber placeholder="请输入赠送的天数" value={this.state.send_day} min={1} max={10000} onChange={that.change_send_day.bind(this)} style={{ width: '300px' }} />

        </Modal>
        <Modal
            title="修改用户备注"
            visible={this.state.remark_visible}
            onOk={this.submit_remark}
            onCancel={this.hidden_remark}
            okText="保存"
            cancelText="取消"
          >
            <Input placeholder="请输入用户备注" value={this.state.remark}  onChange={this.change_remark.bind(this)} style={{ width: '300px' }} />

          </Modal>

        <Table columns={columns} dataSource={this.state.data} className="table-view" pagination={{
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:50,
        }}/>
      </Card>

    );
  }
}

Subscribe_list = Form.create()(Subscribe_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    current_page:state.current_page
  }
};
export default connect(mapStateToProps)(Subscribe_list)