import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import ReactEcharts from 'echarts-for-react';
import { Table, Divider, Button, Card, message, Popconfirm, Form, Input } from 'antd';
import { Redirect } from 'react-router-dom'
import { getDay } from '../../../utils/index'
import { connect } from "react-redux"
import { Chart } from '@antv/g2';
const FormItem = Form.Item;
class User_statistic extends React.Component {
  state = {
    goto_teacher_add: false,
    goto_teacher_edit: false,
    goto_teacher_edit_path: '',
    data: [],
    user_amount: 0
  }



  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();

    this.init_data(data.mobile);


  }
  componentDidMount() {
    let that = this;


  }
  componentWillMount() {

    this.init_data();

    
  }
  // 初始化数据
  init_data(mobile = '') {
    let that=this;
    Axios.ajax({
      url: '/user/statistic.php',
      data: {
        token: this.props.userinfo.token,
        mobile: mobile
      }
    }).
      then((res) => {

        that.setState({
          data: res.data
        })
        that.init_static_view(this.state.data.user_count,"total-user");
        that.init_static_view(this.state.data.user_dau,"user-dau");
        that.init_static_view(this.state.data.product_dau,"product-dau");
        that.init_static_view(this.state.data.course_dau,"h5-dau");
        
      })
  }
 



  
    init_static_view(origin_data,res){
     
      let that=this;
      let  data = [];
      for(let i =0;i<origin_data.length;i++){
          let tmp={};
          tmp.date=getDay(i-30);
          tmp.value=origin_data[i];
          data.push(tmp);
      }
      let chart = new Chart({
        container: res,
        autoFit: true,
        height: 250,
      });
      chart.data(data);
      chart.legend({
        position: 'top-right'
      });
      chart.tooltip({
        crosshairs: {
          type: 'line'
        }
      });
      chart.area().position('date*value');
      chart.line().position('date*value')
      chart.render();
    }
      render() {
        return (

          <Card >
            <label>视频点播端dau ( 单位:人 )</label>
            <div id="h5-dau" className="g2-view"></div>
            <label>软件分析端dau ( 单位:人 )</label>
            <div id="product-dau" className="g2-view"></div>
            <label>日活跃用户统计图 ( 单位:人 )</label>
            <div id="user-dau" className="g2-view"></div>
            <label>用户总数折线图 ( 单位:人 )</label>
            <div id="total-user" className="g2-view"></div>
            
            
    
          </Card>

        );
      }
    }

    User_statistic = Form.create()(User_statistic);
    const mapStateToProps = state => {
      return {
        userinfo: state.userinfo
      }
    };
    export default connect(mapStateToProps)(User_statistic)