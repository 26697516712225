import React from "react";
import  './index.less'
import  { connect } from "react-redux"
import  { login }  from  '../../redux/action'
import cookie from 'react-cookies'
import Axios from '../../axios/index'
import { Form, Input, Button, message, Icon } from "antd";


const FormItem = Form.Item;

 class FormLogin extends  React.Component{
    
    handleSubmit = ()=>{
        let userInfo = this.props.form.getFieldsValue();

        this.props.form.validateFields((err,values)=>{
            if(!err){
                Axios.ajax({
                    url:'/auth/login.php',
                    data:{
                        user: userInfo.nickname,
                        pswd: userInfo.password
                      }
                  }).
                  then((res)=>{
   
                    message.success(`登录成功`);
                    const userinfo=res.data;

                    cookie.save("userinfo",userinfo);
                    const { dispatch } = this.props;
                    dispatch(login(userinfo));
                  })
            }
        })
    }
    
     render(){
        const { getFieldDecorator } = this.props.form;
         return (

          <div className="form-login-div">
            <Form className="form-login">
                    <FormItem>
                        <h3 className="font1  ">征牛大数据</h3>
                    </FormItem>
                    <FormItem>
                        {
                            getFieldDecorator('nickname', {

                                rules: [
                                    {
                                        required: true,
                                        message: '请输入用户名'
                                    },

                                    {
                                        pattern: new RegExp('^\\w+$', 'g'),
                                        message: '用户名必须为字母或者数字'
                                    }
                                ]
                            })(
                                
                                <Input autocomplete="new-password" prefix={<Icon type="user" />} placeholder="请输入用户名" className="input-style" />
                            )
                        }
                    </FormItem>
                    <FormItem>
                        {
                            getFieldDecorator('password', {

                                rules: [
                                    {
                                        required: true,
                                        message: '请输入密码'
                                    },
                                    {
                                        pattern: new RegExp('^\\w+$', 'g'),
                                        message: '用户名必须为字母或者数字'
                                    }
                                ]
                            })(
                                <div className="loginform-input-row-view">

                                    <Input autocomplete="new-password" prefix={<Icon type="lock" />} type="password" placeholder="请输入密码" className="input-style" onPressEnter={this.handleSubmit} />
                                    {/* <NavLink to="/reset_pswd" className="goto-password-hint-view">忘记密码</NavLink> */}
                                </div>
                             )
                        }
                    </FormItem>

                    <FormItem >
                        <div className="loginform-button-view">
                            <Button type="primary" onClick={this.handleSubmit} className="loginform-button-style">登录</Button>
                            {/* <NavLink to="/register"><Button type="primary" className="loginform-button-style">注册</Button></NavLink> */}
                        </div>

                        {/* <a href="#">暂时没有账号，点击去注册</a> */}
                    </FormItem>

                </Form>
           </div>
         );
     }

}
FormLogin=connect()(FormLogin)
export default Form.create({})(FormLogin)