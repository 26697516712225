import React from 'react'
import './index.less'
import Axios from '../../../axios/index'

import {Table, InputNumber, Button, Card, message, Popconfirm, Form, Input ,Select,Icon} from 'antd';
import { Redirect,NavLink } from 'react-router-dom'
import { connect } from "react-redux"


const Option = Select.Option;
const FormItem = Form.Item;
class Agent_role_add extends React.Component {
  state = {
    data: [],
    agent_role_list: [],
    goto_list_role_gate: false,
  }


  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    let that = this;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        data.token = this.props.userinfo.token;
        Axios.ajax({
          url: '/agent_user/edit.php',
          data: data
        }).
          then((res) => {
            message.success("保存成功");
            that.setState({
              goto_list_role_gate: true
            })
          })
      }
    })
  }
  componentWillMount() {
    this.init_data();
    this.get_agent_list();
  }
  // 初始化数据
  init_data(){
    let that=this;
    Axios.ajax({
      url: '/agent_user/lst.php',
      data: {
        token: this.props.userinfo.token,
        agent_user_id:this.props.agent_user_id
      }
    }).
      then((res) => {
        this.setState({
          data: res.data,
        })
        this.props.form.setFieldsValue({
          mobile: res.data.mobile,
          agent_role_id: res.data.id
        });
      })
  }
  // 获取代理商列表
  get_agent_list() {
    let that = this;
    Axios.ajax({
      url: '/agent_role/lst.php',
      data: {
        token: this.props.userinfo.token
      }
    }).
      then((res) => {
        this.setState({
          agent_role_list: res.data,
        })
      })

  }
  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.goto_list_role_gate) {
      return (
        <Redirect to="/agent_user/lst" />
      );
    }

    return (


      <Card  className="window-view " title={<div className="window-header-view" >
      <NavLink to="/agent_user/lst"><Button type="primary" className="window-close-view"><Icon type='arrow-left' />返回</Button></NavLink>
      <div className="window-title-view">新增代理商</div>
    </div>}>
      <div className="content-wrap">
        <Form className="setting-form-teacher-add" >
          <Form.Item
            label="代理商手机号(该手机号必须为系统中已绑定的手机号)"
          >
            {getFieldDecorator('mobile', {
            
            })(
              <Input placeholder="请输入绑定的手机号" className="course-input-style" disabled />
            )}
          </Form.Item>

          <Form.Item
            label="代理商角色"
          >
            {getFieldDecorator('agent_role_id', {
              rules: [{
                required: true, message: '请选择代理商角色',
              }],
            })(
              <Select
                placeholder="请选择代理商角色"
                className="course-input-style"
              >
                {
                  this.state.agent_role_list.map((item) => {

                    return <Option  key={item.id}  value={item.id}>{item.title}</Option>
                  })
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item className="layout-center">
            <Button type="primary" onClick={this.handleSubmit}>保存</Button>
          </Form.Item>

        </Form>
        </div>
      </Card>
    );
  }
}

Agent_role_add = Form.create()(Agent_role_add);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    agent_user_id:state.agent_user_id
  }
};
export default connect(mapStateToProps)(Agent_role_add)