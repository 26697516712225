import React from 'react'
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import Axios from '../../axios/index'
import Fetch from 'fetch'


import {
    Upload, Icon
} from 'antd';
class Test extends React.Component {
    state = {
        text: "<p>lll</p>",
    }
    handleChange = (value) => {
        this.setState({ text: value })
        console.log(this.state)
    }
    componentDidMount() {

    }
    // 上传图片
    upload_image() {
        console.log("上传图片")
    }
    // 上传视频
    beforeUploadVideo = (file) => {
        let size = 1024 * 1024 * 1;
        let file1 = file.slice(0, size);
        let file2 = file.slice(size);
        let data = new FormData();
        data.append('file_1', file1);

        fetch("http://api.educate.simpleoj.cn/server/test.php", {
            method: 'POST',
            body: data
        })
            .then(function (response) {
                return response.json();
            })
            .then((e) => {
                console.log(e)
            })

        return false;
    }
    render() {
        let that = this;
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">上传</div>
            </div>
        );

        return (
            <div className="text-editor">
                <Upload
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={this.beforeUploadVideo}
                >
                    {uploadButton}
                </Upload>
            </div>
        );
    }
}
export default Test