/**
 * reducer
 */


import { type } from '../action';
import cookie from 'react-cookies'

let userinfo = cookie.load("userinfo");
if (userinfo == "undefined") {
    userinfo = null;
}
//console.log(userinfo)
const initialState = {
    userinfo: userinfo,
    course_id: null,
    course_content_id: null,
    agent_role_id: null,
    agent_user_id: null,
    current_user_id: '',
    current_page:1,
    router_path: '/home'
}

const ebikeData = (state = initialState, action) => {
    switch (action.type) {
        case type.LOGIN:

            return {
                ...state,
                userinfo: action.userinfo
            };
        case type.QUIT:
            return {
                ...state,
                userinfo: action.userinfo
            };
        case type.COURSE_ID:
            return {
                ...state,
                course_id: action.course_id
            };

        case type.TEACHER_ID:
            return {
                ...state,
                teacher_id: action.teacher_id
            };
        case type.COURSE_CATE_ID:
            return {
                ...state,
                course_cate_id: action.course_cate_id
            };
        case type.SET_SHOP_ID:
            return {
                ...state,
                shop_id: action.shop_id
            };
        case type.SET_COURSE_CONTENT_ID:
            return {
                ...state,
                course_content_id: action.course_content_id
            };
        case type.SET_AGENT_ROLE_ID:
            return {
                ...state,
                agent_role_id: action.agent_role_id
            };
        case type.SET_AGENT_USER_ID:
            return {
                ...state,
                agent_user_id: action.agent_user_id
            };
        case type.SET_ROUTER_PATH:
            return {
                ...state,
                router_path: action.router_path
            };
        case type.SET_CURRENT_USER_ID:
            return {
                ...state,
                current_user_id: action.current_user_id
            };
            case type.SET_CURRENT_PAGE:
                return {
                    ...state,
                    current_page: action.current_page
                };
        default:
            return { ...state };
    }
};

export default ebikeData;