import React from 'react'
import { connect } from "react-redux"
import Admin from './admin'

import Home from './pages/home';
import Test from './pages/test';
import FormLogin from './pages/login';
import Teacher_list from './pages/teacher/list/';
import Teacher_add from './pages/teacher/add/';
import Teacher_edit from './pages/teacher/edit/';

import Course_cate_list from './pages/course_cate/list/';
import Course_cate_add from './pages/course_cate/add/';
import Course_cate_edit from './pages/course_cate/edit/';

import Course_list from './pages/course/list/';
import Course_add from './pages/course/add';
import Course_edit from './pages/course/edit/';
import Course_statistic from './pages/course/statistic/';

import Order_list from './pages/order/list/';
import User_list from './pages/user/list/';
import Comment_list from './pages/comment/list/';
import Withdraw_list from './pages/withdraw/list/';
import Distribute_setting from './pages/setting/distribute/';
import Consult_server from './pages/setting/consult_server/';


import Video_list from './pages/video/list';
import Video_add from './pages/video/add';
import Video_edit from './pages/video/edit';

import Order_statistic from './pages/order/statistic';
import User_statistic from './pages/user/statistic';

import Agent_role_lst from './pages/agent_role/lst';
import Agent_role_add from './pages/agent_role/add';
import Agent_role_edit from './pages/agent_role/edit';

import Agent_user_lst from './pages/agent_user/lst';
import Agent_user_add from './pages/agent_user/add';
import Agent_user_edit from './pages/agent_user/edit';

import Agent_bonus_record from './pages/agent_bonus_record';
import Agent_withdraw_record from './pages/agent_withdraw_record'

import Subscribe_list from './pages/subscribe/list'
import Subscribe_send from './pages/subscribe/send'

import Coupon_list from './pages/coupon/list'
import Coupon_get_list from './pages/coupon/get_list'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'

import Distribute_list from './pages/distribute/list'
import User_detail from './pages/user/detail'
class IRouter extends React.Component {

    render() {

        if (this.props.userinfo==null) {
            return (
                <FormLogin />
            );
        }

        return (

            <HashRouter>
     
                    <Switch>

                        <Route path="/" render={() =>
                            <Admin>
                                <Route path='/home' component={Home} />
                                {/* <Route path='/test' component={Test} /> */}
                                <Route path='/teacher/list' component={Teacher_list} />
                                <Route path='/teacher/add' component={Teacher_add} />
                                <Route path='/teacher/edit' component={Teacher_edit} />

                                <Route path='/order/list' component={Order_list} />
                                <Route path='/user/list' component={User_list} />
                                <Route path='/order/statistic' component={Order_statistic} />
                                <Route path='/user/statistic' component={User_statistic} />
                                <Route path='/withdraw/list' component={Withdraw_list} />

                                <Route path='/course_cate/list' component={Course_cate_list} />
                                <Route path='/course_cate/add' component={Course_cate_add} />
                                <Route path='/course_cate/edit' component={Course_cate_edit} />

                                <Route path='/course/list' component={Course_list} />
                                <Route path='/course/add' component={Course_add} />
                                <Route path='/course/edit' component={Course_edit} />
                                <Route path='/course/statistic' component={Course_statistic} />


                                <Route path='/video/list' component={Video_list} />
                                <Route path='/video/add' component={Video_add} />
                                <Route path='/video/edit' component={Video_edit} />
                           
                                <Route path='/setting/distribute' component={Distribute_setting} />
                                <Route path='/setting/consult_server' component={Consult_server} />

                                <Route path='/agent_role/lst' component={Agent_role_lst} />
                                <Route path='/agent_role/add' component={Agent_role_add} />
                                <Route path='/agent_role/edit' component={Agent_role_edit} />

                                <Route path='/agent_user/lst' component={Agent_user_lst} />
                                <Route path='/agent_user/add' component={Agent_user_add} />
                                <Route path='/agent_user/edit' component={Agent_user_edit} />
                                
                                <Route path='/agent_bonus_record' component={Agent_bonus_record} />
                                <Route path='/agent_withdraw_record' component={Agent_withdraw_record} />
                                <Route path='/subscribe/list' component={Subscribe_list} />
                                <Route path='/subscribe/send' component={Subscribe_send} />

                                <Route path='/coupon/list' component={Coupon_list} />
                                <Route path='/coupon/user_list' component={Coupon_get_list}/>
                                <Route path='/distribute/list' component={Distribute_list}/>

                                <Route path='/user/detail' component={User_detail}/>
                                <Route path='/comment/list' component={Comment_list}/>
                                
                                
                                <Redirect to="/home"/>
                            </Admin>
                        } />


                    </Switch>

        
            </HashRouter>
        );
    }
}
const mapStateToProps = state => {

    return {
        userinfo: state.userinfo,
        course_id:state.course_id
    }
};
export default connect(mapStateToProps)(IRouter)