import React from 'react'
import './index.less'
import Axios from '../../../axios/index'

import { Table, InputNumber, Button, Card, message, Popconfirm, Form, Input,Icon } from 'antd';
import { Redirect,NavLink } from 'react-router-dom'
import { connect } from "react-redux"
const FormItem = Form.Item;
class Agent_role_edit extends React.Component {
  state = {
    data: [],
    goto_list_role_gate: false,
  }


  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    let that = this;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        data.token = this.props.userinfo.token;
        data.agent_role_id=this.props.agent_role_id;
        Axios.ajax({
          url: '/agent_role/edit.php',
          data: data
        }).
          then((res) => {
            message.success("保存成功");
            that.setState({
              goto_list_role_gate: true
            })
          })
      }
    })
  }
  componentWillMount() {
    if (this.props.agent_role_id) {
      this.init_data()
    }
  }
  // 初始化数据
  init_data() {
    let that=this;
    let data={};
    data.token = this.props.userinfo.token;
    data.agent_role_id=this.props.agent_role_id;
    Axios.ajax({
      url: '/agent_role/lst.php',
      data: data
    }).
      then((res) => {
         that.setState({
          data:res.data
         })
         this.props.form.setFieldsValue({
          extra_bonus_rate_one: res.data.extra_bonus_rate_one,
          extra_bonus_rate_two: res.data.extra_bonus_rate_two,
          extra_bonus_rate_other: res.data.extra_bonus_rate_other,
          title: res.data.title
        });
      })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.goto_list_role_gate || !this.props.agent_role_id) {
      return (
        <Redirect to="/agent_role/lst" />
      );
    }
    return (
      <Card  className="window-view " title={<div className="window-header-view" >
      <NavLink to="/agent_role/lst"><Button type="primary" className="window-close-view"><Icon type='arrow-left' />返回</Button></NavLink>
      <div className="window-title-view">编辑角色</div>
    </div>}>
      <div className="content-wrap">
        <Form className="setting-form-teacher-add" >
          <Form.Item
            label="角色名称"
          >
            {getFieldDecorator('title', {
              rules: [{
                required: true, message: '角色名称不能为空',
              }],
            })(
              <Input placeholder="请输入角色名称" className="course-input-style" />
            )}
          </Form.Item>

          <Form.Item
            label="直接推广额外奖励比例(%)"
          >
            {getFieldDecorator('extra_bonus_rate_one', {
              rules: [{
                required: true, message: '请输入1-100之间的数字',
              }],

            })(
              <InputNumber min={0} max={100} placeholder="请输入1-100之间的数字" style={{ width: 300 }} />
            )}
          </Form.Item>

          <Form.Item
            label="间接推广额外奖励比例(%)"
          >
            {getFieldDecorator('extra_bonus_rate_two', {
              rules: [{
                required: true, message: '请输入1-100之间的数字',
              }],

            })(
              <InputNumber min={0} max={100} placeholder="请输入1-100之间的数字" style={{ width: 300 }} />
            )}
          </Form.Item>

          <Form.Item
            label="其它推广额外奖励比例(%)"
          >
            {getFieldDecorator('extra_bonus_rate_other', {
              rules: [{
                required: true, message: '请输入1-100之间的数字',
              }],

            })(
              <InputNumber min={0} max={100} placeholder="请输入1-100之间的数字" style={{ width: 300 }} />
            )}
          </Form.Item>


          <Form.Item className="layout-center">
            <Button type="primary" onClick={this.handleSubmit}>提交</Button>
          </Form.Item>

        </Form>
        </div>
      </Card>
    );
  }
}

Agent_role_edit = Form.create()(Agent_role_edit);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    agent_role_id: state.agent_role_id
  }
};
export default connect(mapStateToProps)(Agent_role_edit)