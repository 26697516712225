import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import { Table, Divider, Button, Card, message, Modal, Form, Input } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import { set_agent_user_id } from '../../../redux/action'

const FormItem = Form.Item;
class Agent_user extends React.Component {
  state = {
    data: [],
    extra_price:'',
    visible:false,
    current_user_id:'',
    current_data:{}
  }
  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    this.init_data(data.mobile);
  }
  componentWillMount() {
    this.init_data();
  }
  // 初始化数据
  init_data(mobile = '') {
    Axios.ajax({
      url: '/agent_user/lst.php',
      data: {
        token: this.props.userinfo.token,
        mobile: mobile
      }
    }).
      then((res) => {
        this.setState({
          data: res.data,

        })
      })
  }
  // 编辑角色
  edit = (data) => {
    const { dispatch } = this.props;
    dispatch(set_agent_user_id(data.id));
  }
  // 显示提现窗口
  withdraw = (data) => {

    this.setState({
      visible:true,
      current_user_id:data.user_id,
      current_data:data
    })
  }
  // 提交发放奖励
  handle_submit=()=>{
    let that=this;
    let userinfo=that.state.current_data;
    let data={};
    data.token=this.props.userinfo.token;
    data.extra_price=this.state.extra_price;
    data.user_id=this.state.current_user_id;
    
    if(!data.extra_price){
      message.info("发放金额不能为空!");

      return false;
    }
    let remain_amount=userinfo.agent_bonus_amount-userinfo.agent_withdraw_amount;
    remain_amount=remain_amount.toFixed(2);

    // 金额不可超过可提现金额
    if(remain_amount<data.extra_price){
      message.info("发放奖励不能超过可提现金额!");
      return false;
    }
    
    Axios.ajax({
      url: '/agent_bonus/apply.php',
      data: data
    }).
      then((res) => {
        message.info("操作成功!");
        this.setState({
          extra_price:'',
          visible:false
        })
         that.init_data();
      })
  }
  // 取消发放奖励
  handleCancel=()=>{

    this.setState({
      visible:false
    })
  }
  // 获取发放奖励的值
  change_extra_price(e){
    const { value } = e.target;
    this.setState({
      extra_price:value
    })
  }
  // 失去焦点时触发
  blur_extra_price (){
    let extra_price=parseFloat(this.state.extra_price);
    extra_price=extra_price.toFixed(2);
    // console.log(extra_price);
    this.setState({
      extra_price:extra_price
    })
  }

  render() {
    let that=this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '代理商昵称',
      dataIndex: 'nickname',
      align: 'center',
    }, {
      title: '角色',
      dataIndex: 'title',
      align: 'center',
    }, {
      title: '手机号',
      dataIndex: 'mobile',
      align: 'center',
    }, {
      title: '可提现余额(元)',
      dataIndex: 'mobile',
      align: 'center',
      render: (text, data) => {
        let remain_amount = data.agent_bonus_amount - data.agent_withdraw_amount;
        remain_amount = remain_amount.toFixed(2);
        return (
          <div>{remain_amount}</div>
        )

      }
    }, {
      title: '累计奖励金额(元)',
      dataIndex: 'agent_bonus_amount',
      align: 'center',
    }, {
      title: '累计提现金额(元)',
      dataIndex: 'agent_withdraw_amount',
      align: 'center',
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <div className="min-width-controll">
          <NavLink to="/agent_user/edit" ><Button type="primary" onClick={this.edit.bind(this, record)} >修改</Button></NavLink>
          <Divider type="vertical" />
          <Button type="primary" onClick={this.withdraw.bind(this, record)} >发放奖励</Button>
          {/* <Divider type="vertical" />
          <Popconfirm title="是否删除该角色？" okText="是" cancelText="否" onConfirm={this.delete.bind(this, record, index)}>
            <Button type="primary">删除</Button>
          </Popconfirm> */}
        </div>
      )
    }
    ];
    return (
      <Card >
        <div className="form-search-div">
          <Form layout="inline" className="form-login">

            <FormItem label="代理商手机号">
              {
                getFieldDecorator('mobile', {

                })(
                  <Input placeholder="请输入代理商手机" className="input-style" onPressEnter={this.handleSubmit} />
                )
              }

            </FormItem>

            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style">搜索</Button>
            </FormItem>
          </Form>
        </div>
        <NavLink to="/agent_user/add" className="teacher-header-wrap">
          <Button type="primary"  >新增代理商</Button>
        </NavLink>
        <Table columns={columns} dataSource={this.state.data} className="table-view" />
        <Modal
          title="发放奖励"
          visible={this.state.visible}
          onOk={this.handle_submit}
          onCancel={this.handleCancel}
          okText="提交"
          cancelText="取消"
        >
          <Input placeholder="请输入已发放奖励金额" type="number" value={this.state.extra_price} onBlur={this.blur_extra_price.bind(this)}  onChange={that.change_extra_price.bind(this)}  />

        </Modal>
      </Card>

    );
  }
}

Agent_user = Form.create()(Agent_user);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Agent_user)