import React from 'react'
import './index.less'
import Axios from '../../../axios/index'

import { Table, InputNumber, Button, Card, message, Popconfirm, Form, Input } from 'antd';
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
const FormItem = Form.Item;
class Distribute_setting extends React.Component {
  state = {
    data: []
  }


  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    if (data.share_one_rate == this.state.data.share_one_rate && data.share_two_rate == this.state.data.share_two_rate && data.super_share_two_rate == this.state.data.super_share_two_rate && data.super_share_one_rate == this.state.data.super_share_one_rate) {
      return false;
    }
    data.token = this.props.userinfo.token;
    Axios.ajax({
      url: '/setting/edit.php',
      data: data
    }).
      then((res) => {
        message.success("修改成功");
        this.init_data();
      })
  }
  componentWillMount() {
    this.init_data();
  }
  // 初始化数据
  init_data(mobile = '') {
    Axios.ajax({
      url: '/setting/list.php',
      data: {
        token: this.props.userinfo.token
      }
    }).
      then((res) => {
        this.setState({
          data: res.data
        })
        this.props.form.setFieldsValue({
          super_share_one_rate: res.data.super_share_one_rate,
          super_share_two_rate: res.data.super_share_two_rate,
          share_one_rate: res.data.share_one_rate,
          share_two_rate: res.data.share_two_rate
        });
      })
  }
  render() {
    const { getFieldDecorator } = this.props.form;


    return (


      <Card className="content-wrap">

        <Form className="setting-form-teacher-add" >
          <div className="setting-label-div"> 普通推广</div>
          <Form.Item
            label="普通推广专栏直接分销比例 (单位：%)"
          >
            {getFieldDecorator('share_one_rate', {
              rules: [{
                required: true, message: '请输入1-100之间的数字',
              }],

            })(
              <InputNumber min={1} max={100} placeholder="请输入1-100之间的数字" style={{ width: 300 }} />
            )}
          </Form.Item>

          <Form.Item
            label="普通推广专栏间接分销比例 (单位：%)"
          >
            {getFieldDecorator('share_two_rate', {
              rules: [{
                required: true, message: '请输入1-100之间的数字',
              }],

            })(
              <InputNumber min={1} max={100} placeholder="请输入1-100之间的数字" style={{ width: 300 }} />
            )}
          </Form.Item>
          <div className="setting-label-div"> 高级推广(用户推广已订阅专栏)</div>
          <Form.Item
            label="高级推广专栏直接分销比例 (单位：%)"
          >
            {getFieldDecorator('super_share_one_rate', {
              rules: [{
                required: true, message: '请输入1-100之间的数字',
              }],

            })(
              <InputNumber min={1} max={100} placeholder="请输入1-100之间的数字" style={{ width: 300 }} />
            )}
          </Form.Item>

          <Form.Item
            label="高级推广专栏间接分销比例 (单位：%)"
          >
            {getFieldDecorator('super_share_two_rate', {
              rules: [{
                required: true, message: '请输入1-100之间的数字',
              }],

            })(
              <InputNumber min={1} max={100} placeholder="请输入1-100之间的数字" style={{ width: 300 }} />
            )}
          </Form.Item>


          <Form.Item className="layout-center">
            <Button type="primary" onClick={this.handleSubmit}>保存</Button>
          </Form.Item>

        </Form>
      </Card>
    );
  }
}

Distribute_setting = Form.create()(Distribute_setting);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Distribute_setting)