import React from 'react'
import './index.less'
import Axios from '../../../axios/index'

import { Table, Divider, Button, Card, message, InputNumber, Form, Input, Modal } from 'antd';
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
const FormItem = Form.Item;
class Subscribe_send extends React.Component {
  state = {
    goto_teacher_add: false,
    goto_teacher_edit: false,
    visible: false,
    goto_teacher_edit_path: '',
    send_day: '',
    current_id: '',
    withdraw_amount: 0,
    data: []
  }

  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();

    this.init_data(data.nickname);


  }
  componentWillMount() {

    this.init_data();
  }
  // 初始化数据
  init_data(nickname = '') {
    Axios.ajax({
      url: '/subscribe/give_list.php',
      data: {
        token: this.props.userinfo.token,
        nickname: nickname
      }
    }).
      then((res) => {
        this.setState({
          data: res.data
        })
      })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    let that = this;
    const columns = [{
      title: '订阅用户',
      dataIndex: 'nickname',
      align: 'center',
    }, {
      title: '订阅专栏',
      align: 'center',
      dataIndex: 'title',
    }, {
      title: '赠送时间(天)',
      align: 'center',
      dataIndex: 'give_day',
    }, {
      title: '创建时间',
      align: 'center',
      dataIndex: 'create_time',
    }
    ];


    return (

      <Card >
        <div className="form-search-div">
          <Form layout="inline" className="form-login">

            <FormItem label="用户昵称">
              {
                getFieldDecorator('nickname', {

                })(
                  <Input placeholder="请输入用户昵称" className="input-style" style={{ width: '300px' }} onPressEnter={this.handleSubmit} />
                )
              }

            </FormItem>

            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style" >搜索</Button>
            </FormItem>
          </Form>
        </div>
       
    
        <Table columns={columns} dataSource={this.state.data} className="table-view" pagination={{

          pageSize:50,
        }}/>
      </Card>

    );
  }
}

Subscribe_send = Form.create()(Subscribe_send);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Subscribe_send)