import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import ReactEcharts from 'echarts-for-react';
import { Table, Divider, Button, Card, message, Popconfirm, Form, Input } from 'antd';
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { Chart } from '@antv/g2';
import { getDay } from '../../../utils/index'
const FormItem = Form.Item;
class Order_statistic extends React.Component {
  state = {
    goto_teacher_add: false,
    goto_teacher_edit: false,
    goto_teacher_edit_path: '',
    data: [],
    order_amount: 0
  }


  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();

    this.init_data(data.out_trade_no);


  }
  componentWillMount() {

    this.init_data();
  }

  init_static_view(origin_data,res){
     
    let that=this;
    let  data = [];
    for(let i =0;i<origin_data.length;i++){
        let tmp={};
        tmp.date=getDay(i-30);
        if(res=="order-price"){
          tmp.value=parseFloat(origin_data[i]);
        }else{
          tmp.value=origin_data[i];
        }
        
        data.push(tmp);
    }
    let chart = new Chart({
      container: res,
      autoFit: true,
      height: 250,
    });
    chart.data(data);
    chart.legend({
      position: 'top-right'
    });
    chart.tooltip({
      crosshairs: {
        type: 'line'
      }
    });
    chart.area().position('date*value');
    chart.line().position('date*value')
    chart.render();
  }
  // 初始化数据
  init_data(out_trade_no = '') {
    let that=this;
    Axios.ajax({
      url: '/user/statistic.php',
      data: {
        token: this.props.userinfo.token,
        out_trade_no: out_trade_no
      }
    }).
      then((res) => {
        console.log(res)
        this.setState({
          data: res.data
          
        })
        that.init_static_view(this.state.data.order_count,"order-count");
        that.init_static_view(this.state.data.order_total_amount,"order-price");
      })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '订单编号',
      dataIndex: 'out_trade_no',
      align: 'center',
    }, {
      title: '实付金额 (单位:元)',
      dataIndex: 'receipt_amount',
      align: 'center'

    }, {
      title: '用户手机号',
      align: 'center',
      dataIndex: 'mobile',
    }
      , {
      title: '支付时间',
      align: 'center',
      dataIndex: 'payment_time',
    }
    ];

    return (

      <Card >
        <label>订单总交易笔数折线图 ( 单位:笔 )</label>
        <div id="order-count" className="g2-view"></div>
        <label>订单总交易额折线图 ( 单位:元 )</label>
        <div id="order-price" className="g2-view"></div>

       
      </Card>

    );
  }
}

Order_statistic = Form.create()(Order_statistic);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Order_statistic)