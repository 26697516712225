import React from 'react'
import  './index.less'
import Axios from '../../../axios/index'
import {set_course_cate_id} from '../../../redux/action'
import { Table, Divider, Button,Card,message,Popconfirm,Form ,Input} from 'antd';
import { Redirect } from 'react-router-dom'
import  { connect } from "react-redux"
const FormItem = Form.Item;
 class Course_cate_list extends React.Component{
    state={
      goto_course_cate_add:false,
      goto_course_cate_edit:false,
      goto_course_cate_edit_path:'',
      data : []
    }
    // 新增讲师
    add=(e)=>{
      this.setState({ goto_course_cate_add: true });
    }
    // 编辑
    edit(index){
      const { dispatch } = this.props;
      dispatch(set_course_cate_id(index.id));
 
      this.setState({
        goto_course_cate_edit:true,
        goto_course_cate_edit_path:"/course_cate/edit"
      })
  }
  // 删除
    delete(index,cur){
   
        Axios.ajax({
          url:'/course_cate/delete.php',
          data:{
            token:this.props.userinfo.token,
               course_cate_id:index.id
            }
        }).
        then((res)=>{
          message.success("移除成功");
          this.init_data();
        })
        
    }
    handleSubmit=()=>{
      let data = this.props.form.getFieldsValue();
 
        this.init_data(data.title);
    
      
    }
    componentWillMount(){
      
          this.init_data();
    }
    // 初始化数据
    init_data(title=''){
      Axios.ajax({
            url: '/course_cate/list.php',
            data:{
              token:this.props.userinfo.token,
              title:title
              }
        }).
        then((res)=>{
              this.setState({
                data:res.data
              })
        })
    }
    render(){
      const { getFieldDecorator } = this.props.form;
        const columns = [{
            title: '标题',
            dataIndex: 'title',
            align:'center',
          }, {
            title: '图标',
            dataIndex: 'pic_url',
            align:'center',
            render: data => (
              <img src={data} className="course-cate-pic"/>
            )
          },  {
            title: '专栏数量(个)',
            dataIndex: 'count',
            align:'center'
          },   {
            title: '操作',
            key: 'action',
            align:'center',
            render: (text, record,index) => (
              <div className="min-width-controll">
               <Button type="primary" onClick={this.edit.bind(this,record)} >修改</Button>
                <Divider type="vertical" />
                <Popconfirm title="是否移除分类？" okText="是" cancelText="否" onConfirm={this.delete.bind(this,record,index)}>
                  <Button type="primary">移除</Button>
               </Popconfirm>
                
              </div>
            ),
          }];
          
          if(this.state.goto_course_cate_add){
            return (
                <Redirect push  to="/course_cate/add"/>
            );
          }
          if(this.state.goto_course_cate_edit){
            
            return (
                <Redirect push  to={this.state.goto_course_cate_edit_path}/>
            );
          }
        return (
        
            <Card >
                 <div className="form-search-div">
                    <Form layout="inline" className="form-login">
            
                    <FormItem label="分类名称">
                    {
                        getFieldDecorator('title', {
                            
                        })(
                          <Input  placeholder="请输入讲师名称" className="input-style" onPressEnter={this.handleSubmit}/>
                          )
                    } 
                                    
                        </FormItem>
                    
                        <FormItem>
                            <Button type="primary" onClick={this.handleSubmit} className="button-style">搜索</Button>
                        </FormItem>
                    </Form>
                  </div>
                <div className="teacher-header-wrap">
                <Button type="primary" onClick={this.add} >新增分类</Button>
                </div>
                
               <Table  columns={columns} dataSource={this.state.data}  className="table-view"/>
            </Card>
            
        );
    }
}

Course_cate_list = Form.create()(Course_cate_list);
const mapStateToProps = state => {
  return {
      userinfo: state.userinfo
  }
};
export  default  connect(mapStateToProps)(Course_cate_list)