import React from 'react'
import './index.less'
import { connect } from "react-redux"
import { getBase64, uploadImageCallBack, get_new_filename } from '../../../utils/index'
import Axios from '../../../axios/index'
import { Redirect ,NavLink} from 'react-router-dom'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftjs from 'draftjs-to-html'
import { EditorState, ContentState } from 'draft-js';
import { client } from '../../../utils/oss'
import { OSS_IMAGE_PATH, ROOT_PATH } from '../../../config/config'
import htmlToDraft from 'html-to-draftjs';
import {
  Form, Input, Icon, Upload, Select, Modal, message, Button, InputNumber, Card, Switch
} from 'antd';

import { Chart } from '@antv/g2';

function getTypeColor(type) {
  if (type === '转基因作物种植面积') { return '#1890ff'; }
  if (type === '耕地总面积') { return '#2fc25b'; }
  if (type === '转基因作物种植占比（%）') { return '#facc14'; }
}
const Option = Select.Option;


const { TextArea } = Input;
class Course_statistic extends React.Component {

  state = {
    data:{}
  };

  componentWillMount() {
    let that = this;
    Axios.ajax({
      url: '/course/statistic.php',
      data: {
        token: this.props.userinfo.token,
        course_id: this.props.course_id
      }
    }).
      then((res) => {
        if (res.data == undefined) {
          this.setState({
            goto_course_list: true
          })
        } else {
          that.setState({
            data:res.data.base_info,
            content_play:res.data.content_play,
            content_study:res.data.content_study
          })

        }
        that.update_content_play(res.data.content_play)
        that.update_content_study(res.data.content_study)
      }).catch((res) => {
        this.setState({
          goto_course_list: true
        })
        
      })
  }

  update_content_play(data){

    const chart = new Chart({
      container: 'g2-content-play',
      width:700,
      autoFit: false,
      height: data.length*45+200,
      padding:[10,100,300,400]
    });
    chart.data(data);
    chart.scale({
      value: {
        alias: '播放量（次）',
      },
    });
    chart.axis('type', {
      title: null,
      tickLine: null,
      line: null,
    });
    
    chart.axis('value', {
      label: null,
      title: {
        offset: 30,
        style: {
          fontSize: 12,
          fontWeight: 300,
        },
      },
    });
    chart.legend(false);
    chart.coordinate().transpose();
    chart
      .interval()
      .position('type*value')
      .size(26)
      .label('value', {
        style: {
          fill: '#8d8d8d',
        },
        offset: 10,
      });
    chart.interaction('element-active');
    chart.render();
  }
  update_content_study(data){

    const chart = new Chart({
      container: 'g2-content-study',
      width:700,
      autoFit: false,
      height: data.length*45+200,
      padding:[10,100,300,400]
    });
    chart.data(data);
    chart.scale({
      value: {
        alias: '学习人数（人）',
      },
    });
    chart.axis('type', {
      title: null,
      tickLine: null,
      line: null,
    });
    
    chart.axis('value', {
      label: null,
      title: {
        offset: 30,
        style: {
          fontSize: 12,
          fontWeight: 300,
        },
      },
    });
    chart.legend(false);
    chart.coordinate().transpose();
    chart
      .interval()
      .position('type*value')
      .size(26)
      .label('value', {
        style: {
          fill: '#8d8d8d',
        },
        offset: 10,
      });
    chart.interaction('element-active');
    chart.render();
  }
  render() {
    let that=this;
    let data=that.state.data;
    console.log(data)

    // if (this.state.goto_course_list) {
    //   return (
    //     <Redirect to="/course/list" />
    //   );
    // }

    return (
      <Card  className="window-view " title={<div className="window-header-view" >
      <NavLink to="/course/list"><Button type="primary" className="window-close-view"><Icon type='arrow-left' />返回</Button></NavLink>
      <div className="window-title-view">{that.state.data.title} - 数据分析</div>
    </div>}>
      <div className="course-content-wrap">
            <div className="cs-header-view">
                <div className="cs-header-row">
                   <text className="cs-font1">累计播放量(次)</text>
                   <text className="cs-font2 income-font">{data.play_count}</text>
                </div>
                <div className="cs-header-row">
                   <text className="cs-font1">累计交易额(元)</text>
                   <text className="cs-font2 income-font">{data.income}</text>
                </div>
                <div className="cs-header-row">
                   <text className="cs-font1">累计学习人数(人)</text>
                   <text className="cs-font2 income-font">{data.people}</text>
                </div>
            </div>
            <div className="canvas-view">

            <div id="g2-content-play" className="g2-max-view"> </div>
            <div id="g2-content-study" className="g2-max-view"> </div>

            </div>
            
            
                
           
        </div>
      </Card>
    );
  }
}

Course_statistic = Form.create()(Course_statistic);



const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    course_id: state.course_id
  }
};
export default connect(mapStateToProps)(Course_statistic)







