import React from 'react'
import './index.less'
import { connect } from "react-redux"
import Axios from '../../../axios/index'
import { Redirect,NavLink } from 'react-router-dom'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftjs from 'draftjs-to-html'
import { getBase64, uploadImageCallBack, get_new_filename } from '../../../utils/index'
import { client } from '../../../utils/oss'
import { OSS_IMAGE_PATH, ROOT_PATH } from '../../../config/config'
import {
  Form, Input, Icon, Upload, Select, Modal, message, Button, InputNumber, Card, Switch
} from 'antd';

const Option = Select.Option;
const { TextArea } = Input;


function handleChange(value) {
  console.log(`selected ${value}`);
}
class Course_add extends React.Component {
  state = {
    image_id: "",
    loading: false,
    teacher_list: [],
    
    course_cate: [],
    is_free: false,
    
    price_rule: [{ "time": '', "price": "" }],
    goto_course_list: false
  };
  onEditorChange = (editorContent) => {

    this.setState({
      editorContent,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    });
  };
  // 提交表单
  handleSubmit = (e) => {
    let data = this.props.form.getFieldsValue();
    let that=this;
  
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if(!that.check_price_rule()){
          message.info(`订阅规则不能为空`)
            return false;
        }
        data.detail = draftjs(data.detail);
        data.token = this.props.userinfo.token;
        if (this.state.is_free) {
          data.is_free = 0;
          data.price_rule=JSON.stringify(that.state.price_rule).toString();
        } else {
          data.is_free = 1;
        }
       
        Axios.ajax({
          url: '/course/add.php',
          data: data
        }).
          then((res) => {
            message.success(`新增专栏成功！`)
            this.setState({
              goto_course_list: true
            })
          })
      }
    });
  }
  
  componentWillMount() {
    this.init_data();
    
    this.init_course_cate();
  }
  // 初始化专栏分类
  init_course_cate() {
    let data = {};
    data.token = this.props.userinfo.token;
    Axios.ajax({
      url: '/course_cate/list.php',
      data: data
    }).
      then((res) => {
        let course_cate_arr = [];
        let tmp = { "id": "0", 'title': "全部分类" };
        course_cate_arr.push(tmp);
        for (let i = 0; i < res.data.length; i++) {
          let tmp2 = {};
          tmp2.id = res.data[i].id;
          tmp2.title = res.data[i].title;
          course_cate_arr.push(tmp2);
        }
        this.setState({
          course_cate: course_cate_arr
        })

      })

  }
  // 初始化数据
  init_data(nickname = '') {
    Axios.ajax({
      url: '/teacher/list.php',
      data: {
        token: this.props.userinfo.token,
        nickname: nickname,
        filter: true
      }
    }).
      then((res) => {
        this.setState({
          teacher_list: res.data
        })
      })
  }
  
  onSwitchChange = (checked) => {
    this.setState({
      is_free: checked
    })
  }
  // 监听订阅时间
  change_price_time = (value, index) => {
    let price_rule = this.state.price_rule;
    price_rule[index].time = value;
    this.setState({
      price_rule: price_rule
    })

  }
  // 监听订阅金额
  change_price_value = (value, index) => {
    let price_rule = this.state.price_rule;
    price_rule[index].price = value;
    this.setState({
      price_rule: price_rule
    })

  }
  // 减少收费规则
  sub_price_rule(index){
    let price_rule = this.state.price_rule;

    price_rule.splice(index);
    this.setState({
      price_rule: price_rule
    })
  }
  
  // 增加收费规则
  add_price_rule(){
    let price_rule = this.state.price_rule;
    let tmp={};
    tmp.price='';
    tmp.time="";
    price_rule.push(tmp);
    this.setState({
      price_rule: price_rule
    })
  }
  // 校验收费规则是否为空
  check_price_rule(){
    let price_rule = this.state.price_rule;
    let is_free=this.state.is_free;
    if(is_free){
      for(let item in price_rule){
        if(price_rule[item].time==''||price_rule[item].price=='') return false;
      }
    }
    
    return true;
  }
  // 图片上传
  handleChange = (info) => {

    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => this.setState({

        loading: false,
      }));
    }
  }
  //上传图片校验
  beforeUpload = (file) => {

    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    if (!isJPG && !isPNG) {
      message.error('仅支持jpg或者png格式!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('文件需小于2MB!');
    }

    let that = this;
    let new_filename = get_new_filename(file.name);
    client.put(OSS_IMAGE_PATH + new_filename, file)
      .then(function (result) {
        message.success("上传成功");
        that.setState({
          imageUrl: ROOT_PATH + OSS_IMAGE_PATH + new_filename
        })
        that.props.form.setFieldsValue({
          course_cover: ROOT_PATH + OSS_IMAGE_PATH + new_filename
        });
      })
      .catch(function (err) {
        Modal.info({
          title: "提示",
          content: err.message
        })
      });
    return false;

  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { editorContent, editorState } = this.state;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;
    let that = this;

    // 设置收费规则
    let price_rule = that.state.price_rule;
    let price_rule_view = price_rule.map((item, index) => {
      return (<div className="price-rule-row-view">
        订阅时间(月)：
        <InputNumber min={1} max={36} placeholder="请输入订阅时限" style={{ width: 150 }} value={item.time} onChange={(value) => { that.change_price_time(value, index) }} />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;订阅费用(元)：
        <InputNumber min={0.1} max={50000} placeholder="请输入订阅费用" style={{ width: 150 }} onChange={(value) => { that.change_price_value(value, index) }} value={item.price} />
        {/* 新增或者减少订阅规则 */}
        {index!=0&&index==price_rule.length-1?<Button type="primary" className="price-rule-font" onClick={that.sub_price_rule.bind(this,index)}>删除订阅规则</Button>:''}
        {index==price_rule.length-1?<Button type="primary" className="price-rule-font" onClick={that.add_price_rule.bind(this)}>新增订阅规则</Button>:''}
      </div>)
    })

    if (this.state.goto_course_list) {
      return (
        <Redirect to="/course/list" />
      );
    }
    return (
      <Card  className="window-view " title={<div className="window-header-view" >
        <NavLink to="/course/list"><Button type="primary" className="window-close-view"><Icon type='arrow-left' />返回</Button></NavLink>
        <div className="window-title-view">新增专栏</div>
      </div>}>
        <div className="course-content-wrap">

        <Form className="course-form-teacher-add" >
          
          <Form.Item
            label="专栏名称"
          >
            {getFieldDecorator('title', {
              rules: [{
                required: true, message: '专栏名称不能为空',
              }],
            })(
              <Input placeholder="请输入专栏名称" className="course-input-style" />
            )}
          </Form.Item>
          <Form.Item
            label="专栏分类"
          >
            {getFieldDecorator('course_cate_id', {
              rules: [{
                required: true, message: '专栏分类不能为空',
              }],
            })(
              <Select
                placeholder="请选择专栏分类"
                className="course-input-style"
              >
                {
                  this.state.course_cate.map((item) => {

                    return <Option key={item.id} >{item.title}</Option>
                  })
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="专栏讲师"
          >
            {getFieldDecorator('teacher_id', {
              rules: [{
                required: true, message: '专栏讲师不能为空',
              }],
            })(
              <Select
                mode="multiple"
                placeholder="请添加专栏相关讲师"
                onChange={handleChange}
                className="course-input-style"
              >
                {
                  this.state.teacher_list.map((item) => {
                    return <Option key={item.id} >{item.nickname}</Option>
                  })
                }
              </Select>
            )}
          </Form.Item>
          
          <Form.Item label="专栏封面">
            {getFieldDecorator('course_cover', {
              rules: [{
                required: true, message: '专栏封面不能为空',
              }],
            })(
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}

                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}>
                {imageUrl ? <img src={imageUrl} alt="avatar" className="course-avatar-pic" /> : uploadButton}
              </Upload>
            )}

          </Form.Item>
          <Form.Item
            label="专栏详情" className="detail-view"
          >
            {getFieldDecorator('detail', {
              rules: [{
                required: true, message: '专栏详情不能为空',
              }],
            })(
              <Editor
                editorState={editorState}
                onContentStateChange={this.onEditorChange}
                onEditorStateChange={this.onEditorStateChange}
                editorClassName="course-wrapper"
                toolbar={{
                  image: {
                    uploadCallback: uploadImageCallBack,
                    previewImage: true,
                  }
                }}
              />
            )}
          </Form.Item>
          
          <Form.Item
            label="是否付费"
          >
            {getFieldDecorator('is_free', {

            })(
              <Switch checkedChildren="付费" unCheckedChildren="免费" onChange={this.onSwitchChange} checked={this.state.is_free} />
            )}
          </Form.Item>

          {this.state.is_free ?<Form.Item
            label="订阅规则"
          >{price_rule_view} </Form.Item>: ''}
          <Form.Item
            label="专栏对应产品编号"
          >
            {getFieldDecorator('product', {

            })(
              <Input placeholder="请输入产品编号" className="course-input-style" />
            )}
          </Form.Item>
          <Form.Item
            label="产品附件"
          >
            {getFieldDecorator('product_annex', {

            })(
              <TextArea placeholder="请输入产品附件信息" className="course-textarea-style" />
            )}
          </Form.Item>

          <Form.Item className="layout-center">
            <Button type="primary" onClick={this.handleSubmit}>提交</Button>
          </Form.Item>

        </Form>
        </div>
      </Card>
    );
  }
}

Course_add = Form.create()(Course_add);



const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,

  }
};
export default connect(mapStateToProps)(Course_add)