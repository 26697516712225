import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import ReactEcharts from 'echarts-for-react';
import { Table, Divider, Button, Card, message, Popconfirm, Form, Input ,Modal} from 'antd';
import { Redirect } from 'react-router-dom'
import { getDay } from '../../../utils/index'
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import * as ACTION from '../../../redux/action/'
import  { set_current_user_id ,set_router_path}  from  './../../../redux/action'
const FormItem = Form.Item;
class User_list extends React.Component {
  state = {
    goto_teacher_add: false,
    goto_teacher_edit: false,
    goto_teacher_edit_path: '',
    wuliu_remark_visible:false,
    wuliu_remark:'',
    order_id:'',
    data: [],
    current_page:1,
    order_amount: 0,
    order_count:0,
  }
  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    this.setState({
      current_page:1
    })
    const { dispatch } = this.props;
    dispatch(ACTION.set_current_page(1));
    this.init_data(data.mobile, data.out_trade_no);
  }
  componentWillMount() {
    this.setState({
      current_page:this.props.current_page
    })
    this.init_data();
  }
  // 初始化数据
  init_data(mobile = '', out_trade_no = '') {
    Axios.ajax({
      url: '/order/list.php',
      data: {
        token: this.props.userinfo.token,
        mobile: mobile,
        out_trade_no: out_trade_no
      }
    }).
      then((res) => {
        this.setState({
          data: res.data.order_list,
          order_amount: res.data.order_amount,
          order_count: res.data.order_count
        })
      })
  }


// 修改备注
modify_wuliu_remark(data){
  let that=this;

  that.setState({
    order_id:data.id,
    wuliu_remark:data.wuliu_remark,
    wuliu_remark_visible:true
  })

}
// 隐藏备注二维码
hidden_wuliu_remark = () => {
  this.setState({
    wuliu_remark_visible: false
  })
}
 // 修改用户备注
 change_wuliu_remark(e){
  const { value } = e.target;

  this.setState({
    wuliu_remark: value
  })
}

// 提交备注修改
submit_wuliu_remark=()=>{
  let that=this;
  let data={};
  data.token=this.props.userinfo.token;
  data.order_id=this.state.order_id;
  data.wuliu_remark=this.state.wuliu_remark;


  Axios.ajax({
   url: '/wuliu/order_wuliu_remark.php',
   data: data
 }).
   then((res) => {
       message.success("保存成功");
       that.hidden_wuliu_remark()
       that.init_data();
   })

}
 // 监听页面切换
 change_page = page => {
  let that=this;
  this.setState({
    current_page: page,
  });
  const { dispatch } = this.props;
  dispatch(ACTION.set_current_page(this.state.current_page));

}

// 跳转到用户详情
goto_user_detail = (data) => {
  // message.info("开发中，敬请期待！")
  const {dispatch}= this.props;
  dispatch(set_current_user_id(data.user_id));
  // dispatch(set_router_path("/user/list"));
  dispatch(ACTION.set_current_page(this.state.current_page));
}
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '订单编号',
      dataIndex: 'out_trade_no',
      align: 'center',
    }, {
      title: '订阅专栏',
      dataIndex: 'title',
      align: 'center'

    }, {
      title: '订阅费用(元)',
      dataIndex: 'receipt_amount',
      align: 'center'

    }, {
      title: '订阅用户',
      dataIndex: 'nickname',
      align: 'center',
      render:(text,data)=>{
        return (<NavLink to="/user/detail"><span style={{color:'green'}} onClick={this.goto_user_detail.bind(this, data)}>{text}</span></NavLink>)
     }
    }, {
      title: '订阅手机号',
      align: 'center',
      dataIndex: 'mobile',
    }, {
      title: '物流备注',
      align: 'center',
      dataIndex: 'wuliu_remark'
    }
      , {
      title: '订阅创建时间',
      align: 'center',
      dataIndex: 'payment_time',
    },{
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <div >
          <Button type="primary" onClick={this.modify_wuliu_remark.bind(this, record)} >物流</Button>


        </div>
      ),
    }


    ];


    return (

      <Card >

        <div className="form-search-div">
          <Form layout="inline" className="form-login">
            <FormItem label="订单编号">
              {
                getFieldDecorator('out_trade_no', {

                })(
                  <Input placeholder="请输入订单编号" className="input-style" onPressEnter={this.handleSubmit} style={{width:'300px'}}/>
                )
              }

            </FormItem>

            <FormItem label="订阅手机号">
              {
                getFieldDecorator('mobile', {

                })(
                  <Input placeholder="请输入订阅手机号" className="input-style" onPressEnter={this.handleSubmit} />
                )
              }

            </FormItem>


            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style">搜索</Button>
            </FormItem>
          </Form>
        </div>
        <div className="orderinfo-header-wrap">
          <text className="orderinfo-header-detail">订单总金额 : {this.state.order_amount}元 </text>
          <text className="orderinfo-header-detail">订单总笔数 : {this.state.order_count}笔 </text>
        </div>

        <Table columns={columns} dataSource={this.state.data} className="table-view" pagination={{
          onChange: this.change_page,
          pageSize:50,
          current: this.state.current_page,

        }} />

        <Modal
            title="修改物流备注"
            visible={this.state.wuliu_remark_visible}
            onOk={this.submit_wuliu_remark}
            onCancel={this.hidden_wuliu_remark}
            okText="保存"
            cancelText="取消"
          >
            <Input placeholder="请输入物流备注" value={this.state.wuliu_remark}  onChange={this.change_wuliu_remark.bind(this)} style={{ width: '100%' }} />

          </Modal>
      </Card>

    );
  }
}

User_list = Form.create()(User_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    current_page:state.current_page
  }
};
export default connect(mapStateToProps)(User_list)