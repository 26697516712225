const menuList = [
    {
        title: '用户管理',
        key: '/user',
        children: [
            {
                title: '用户统计',
                icon:"file" ,
                key: '/user/statistic'
            },
            {
                title: '用户列表',
                icon:"file" ,
                key: '/user/list'
            }
        ],
        icon:"user" 
    },

    {
        title: '订单管理',
        key: '/order',
        icon:"unordered-list" ,
        children: [
            {
                title: '订单统计',
                icon:"file" ,
                key: '/order/statistic',
            },
            {
                title: '订单列表',
                icon:"file" ,
                key: '/order/list',
            }
        ]
    }, 
    {
        title: '订阅管理',
        key: '/subscribe',
        icon:"read" ,
        children: [
            {
                title: '订阅列表',
                icon:"file" ,
                key: '/subscribe/list',
            },
            {
                title: '赠送列表',
                icon:"file" ,
                key: '/subscribe/send'
            }
        ]
    }, 
    {
        title: '专栏管理',
        key: '/course',
        icon:"book" ,
        children: [
            {
                title: '讲师管理',
                icon:"file" ,
                key: '/teacher/list',
            },
            {
                title: '专栏分类',
                icon:"file" ,
                key: '/course_cate/list'
            },
            {
                title: '专栏列表',
                icon:"file" ,
                key: '/course/list'
            }
        ]
    },
    {
        title: '评论管理',
        key: '/comment',
        icon:"book" ,
        children: [
            {
                title: '评论列表',
                icon:"file" ,
                key: '/comment/list',
            }
            
        ]
    },
    {
        title: '提现管理',
        icon:"money-collect" ,
        key: '/withdraw',
        children: [
            {
                title: '提现列表',
                icon:"file" ,
                key: '/withdraw/list',
            }
        ]
    },
    {
        title: '分销管理',
        key: '/distribute',
        icon:"cloud" ,
        children: [
            {
                title: '分销列表',
                icon:"file" ,
                key: '/distribute/list'
            }
        ]

    },
    {
        title:'优惠券管理',
        icon:'account-book',
        key:'/coupon',
        children: [
            {
                title: '优惠券列表',
                icon:"file" ,
                key: '/coupon/list',
            },
            {
                title: '已发放列表',
                icon:"file" ,
                key: '/coupon/user_list',
            }
        ]
    },
    {
        title: '代理商管理',
        key: '/agent',
        icon:"usergroup-add" ,
        children: [
            {
                title: '角色列表',
                icon:"file" ,
                key: '/agent_role/lst'
            },
            {
                title: '代理商列表',
                icon:"file" ,
                key: '/agent_user/lst'
            },
            {
                title: '推广记录',
                icon:"file" ,
                key: '/agent_bonus_record'
            },
            {
                title: '奖励发放记录',
                icon:"file" ,
                key: '/agent_withdraw_record'
            }
        ]
    },
    
    {
        title: '系统管理',
        key: '/setting',
        icon:"setting" ,
        children: [
            {
                title: '分销设置',
                icon:"file" ,
                key: '/setting/distribute'
            },
            {
                title: '客服设置',
                icon:"file" ,
                key: '/setting/consult_server'
            }
        ]

    }
];



export { menuList };