import React from 'react'
import './index.less'
import Axios from '../../../axios/index'
import QRCode from 'qrcode.react';
import { Table, InputNumber, Modal, Button, Card, message, Popconfirm, Form, Input, Divider,Select,Radio } from 'antd';
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { SERVER_ROOT } from '../../../config/config'
const FormItem = Form.Item;
const Option = Select.Option;
class Coupon_list extends React.Component {
  state = {
    goto_teacher_add: false,
    goto_teacher_edit: false,
    visible: false,
    goto_teacher_edit_path: '',
    coupon_title: '',
    coupon_price: '',
    coupon_time: '',
    qrcode_visible: false,
    remain_num_visible: false,
    remain_num: '',
    qrcode_url: '',
    coupon_num: '',
    learn_time:'',
    course_id:undefined,
    limit_price:undefined,
    coupon_type:"SUB",
    course_data:[],
    data: []
  }
// 搜素提交
  handleSubmit = () => {
    let data = this.props.form.getFieldsValue();
    this.init_data(data.title);
  }
  componentWillMount() {
    let that=this
    this.init_data();
    that.get_course_data();
  }
  // 初始化数据
  init_data(title = '') {
    let that = this;
    Axios.ajax({
      url: '/coupon/list.php',
      data: {
        token: this.props.userinfo.token,
        title: title
      }
    }).
      then((res) => {
        this.setState({
          data: res.data
        })
      })
  }
  // 初始化数据
  get_course_data() {
    let that = this;
    Axios.ajax({
      url: '/course/get.php',
      data: {
        token: this.props.userinfo.token
      }
    }).
      then((res) => {
        this.setState({
          course_data: res.data
        })
      })
  }
  // 取消发放奖励
  handleCancel = () => {

    this.setState({
      coupon_title: '',
      coupon_time: '',
      coupon_num: '',
  
      coupon_price: '',
      course_id:undefined,
          limit_price:undefined,
      visible: false
    })
  }
  add_sub_coupon(){
    let that =this
    let data = {};
    data.token = this.props.userinfo.token;
    data.title = that.state.coupon_title;
    data.expire = that.state.coupon_time;
    data.amount = that.state.coupon_price;
    data.num = that.state.coupon_num;
    data.course_id=that.state.course_id;
    data.limit_price=parseInt(that.state.limit_price);
    data.coupon_type=that.state.coupon_type;
    if(data.course_id=="全场通用"){
      data.course_id=-1
    }
    if(data.course_id==undefined){
      message.info("请选择专栏")
      return;
    }
    if(data.limit_price>=0){
    }else{
      message.info("限制金额非法")
      return;
    }
    Axios.ajax({
      url: '/coupon/add.php',
      data: data
    }).
      then((res) => {
        message.success("添加成功!");
        that.setState({
          coupon_title: '',
          coupon_time: '',
          coupon_num: '',
          coupon_type:'SUB',
          coupon_price: '',
          course_id:undefined,
          limit_price:undefined,
          visible: false
        })
        that.init_data();
      })
  }
  add_learn_coupon(){
    let that=this
    let data = {};
    data.token = this.props.userinfo.token;
    data.title = that.state.coupon_title;
    data.expire = that.state.coupon_time;
    data.num = that.state.coupon_num;
    data.learn_time=that.state.learn_time;
    data.course_id=that.state.course_id;
    data.coupon_type=that.state.coupon_type;
    
    data.learn_amount =parseFloat(that.state.coupon_price) ;
    if(this.state.course_id==undefined){
      message.info("请选择专栏")
      return;
    }
    if(this.state.learn_time==undefined||this.state.learn_time==0){
      message.info("请选择体验期")
      return;
    }
    if(data.learn_amount>=0.01){
    }else{
      message.info("体验金额需大于0.01元")
      return;
    }
    Axios.ajax({
      url: '/coupon/add_learn.php',
      data: data
    }).
      then((res) => {
        message.success("添加成功!");
        that.setState({
          coupon_title: '',
          coupon_time: '',
          coupon_num: '',
          coupon_type:'SUB',
          coupon_price: '',
          course_id:undefined,
          limit_price:undefined,
          visible: false
        })
        that.init_data();
      })
  }
  // 新增优惠券
  add_coupon = () => {
    let that = this;
    

    let num=parseInt(that.state.coupon_num)
    let time=parseInt(that.state.coupon_time)
    // console.log(that.state.coupon_type)
    // console.log(num)
    // console.log(time)
    if(that.state.coupon_title==''){
      message.info("优惠券名称不能为空")
      return false;
    }
    if(num<=0||that.state.coupon_num==''){
      message.info("总库存量不能为0")
      return false;
    }
    if(time<=0||that.state.coupon_time==''){
      message.info("有效期不能小于一天")
      return false;
    }
    if(that.state.coupon_type=="SUB"){
      that.add_sub_coupon();

    }else if(that.state.coupon_type=="LEARN"){
      that.add_learn_coupon();
    }
    
  }
  // 添加库存
  submit_remain_num=()=>{
    let that=this;
    let data = {};
    data.token = this.props.userinfo.token;
    data.remain_num=that.state.remain_num;
    data.coupon_id=that.state.current_coupon_id;
    Axios.ajax({
      url: '/coupon/add_remain.php',
      data: data
    }).
      then((res) => {
        message.success("添加成功!");
        that.setState({
          current_coupon_id: '',
          remain_num: '',
          remain_num_visible: false
        })
        that.init_data();
      })
  }
  // 展示优惠券界面
  show_add_coupon = () => {
    this.setState({
      visible: true
    })
  }
  // 监听优惠券标题
  change_coupon_title(e) {
    const { value } = e.target;

    this.setState({
      coupon_title: value
    })
  }
  // 监听优惠金额
  change_coupon_price(e) {
    const { value } = e.target;

    this.setState({
      coupon_price: value
    })
  }
  // 监听优惠有效期
  change_coupon_time = (e) => {
    // const { value } = e.target;
    this.setState({
      coupon_time: e
    })
  }
  change_learn_time= (e) => {
    // const { value } = e.target;
    this.setState({
      learn_time: e
    })
  }
  change_coupon_num = (e) => {
    // const { value } = e.target;
    this.setState({
      coupon_num: e
    })
  }
  change_limit_price= (e) => {
    // const { value } = e.target;
    this.setState({
      limit_price: e
    })
  }
  change_type(value) {
    this.setState({
      course_id:value
    })

  }
  // 生成领券二维码
  produce_code(record, index) {
    let that = this;
    let coupon_id = record.id;
    let qrcode_url = SERVER_ROOT + "mini_api/h5_gate.php?p=/get_coupon&c=" + coupon_id;
    that.setState({
      qrcode_url: qrcode_url,
      qrcode_visible: true
    })

  }
  // 下架优惠券
  delete(record, index) {
    let that = this;
    let coupon_id = record.id;
    let data = {};
    data.coupon_id = coupon_id;
    data.token = this.props.userinfo.token;
    Axios.ajax({
      url: '/coupon/del.php',
      data: data
    }).
      then((res) => {
        message.success("下架成功!");
        that.init_data();
      })
  }
  // 隐藏二维码
  hiden_qrcode = () => {
    this.setState({
      qrcode_url: '',
      qrcode_visible: false
    })
  }
  // 隐藏增加库存界面
  hidden_remain_num = () => {
    this.setState({
      remain_num_visible: false,
      remain_num: '',
    })
  }
  // 新增优惠券库存
  add_coupon_num  (data){
  
    this.setState({
      current_coupon_id:data.id,
      remain_num_visible: true
    })
  }
  // 监听库存变量
  change_remain_num(v){
    this.setState({
      remain_num: v
    })
  }
  // 切换radio
  change_radio = (e) => {
    this.setState({
      coupon_type: e.target.value
    })

      this.setState({
        course_id:undefined
      })
    

  }
  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '标题',
      dataIndex: 'title',
      align: 'center'

    }, {
      title: '类型',
      dataIndex: 'course_id',
      align: 'center',
      render: (text, data) => {
        let type_str='';
        if(data.coupon_type=="SUB"){
          type_str="（满减券）"
        }else if(data.coupon_type=="LEARN"){
          type_str="（体验券）"
        }
        if(text==-1){
        return (<span >{type_str}全场通用</span>);
        }
        
        return (<span >{type_str}限{data.course_title}专用</span>);
      }

    },  {
      title: '使用限制',
      dataIndex: 'limit_price',
      align: 'center',
      render: (text, data) => {
        if(text==0){
          return (<span >无</span>);
        }
        
        return (<span >满{data.limit_price}可用</span>);
      }

    },  {
      title: '满减/体验(元)',
      dataIndex: 'amount',
      align: 'center',
      render: (text, data) => {
        if(text){
          return (<span >{text}</span>)
        }
        
        return (<span >无</span>)
      }


    }, {
      title: '有效期(天)',
      dataIndex: 'expire',
      align: 'center'

    },  {
      title: '体验期(天)',
      dataIndex: 'learn_time',
      align: 'center',
      render: (text, data) => {
        if(text){
          return (<span >{text}</span>)
        }
        
        return (<span >无</span>)
      }


    }, {
      title: '总发放量(个)',
      dataIndex: 'num',
      align: 'center'

    }, {
      title: '库存(个)',
      dataIndex: 'remain',
      align: 'center',
      render: (text, data) => {
        let remain = data.num - data.remain;
        return (<span >{remain}</span>);
      }

    }, {
      title: '创建时间',
      align: 'center',
      dataIndex: 'create_time',
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <div className="min-width-controll">
          <Button type="primary" onClick={this.add_coupon_num.bind(this, record)}>增加库存</Button>
          <Divider type="vertical" />
          <Button type="primary" onClick={this.produce_code.bind(this, record, index)}>生成领券二维码</Button>
          <Divider type="vertical" />
          <Popconfirm title="是否下架该优惠券？" okText="是" cancelText="否" onConfirm={this.delete.bind(this, record, index)}>
            <Button type="primary">下架</Button>
          </Popconfirm>
        </div>
      )
    }

    ];


    return (

      <Card >
        <div className="form-search-div">
          <Form layout="inline" className="form-login">

            <FormItem label="优惠券标题">
              {
                getFieldDecorator('title', {

                })(
                  <Input placeholder="请输入优惠券标题" className="input-style" style={{ width: '300px' }} onPressEnter={this.handleSubmit} />
                )
              }
            </FormItem>
            <FormItem>
              <Button type="primary" onClick={this.handleSubmit} className="button-style" >搜索</Button>
            </FormItem>
          </Form>
        </div>
        <div className="teacher-header-wrap">
          <Button type="primary" onClick={this.show_add_coupon} >新增优惠券</Button>
        </div>

        <Table columns={columns} dataSource={this.state.data} className="table-view" />
        <Modal
          title="新增优惠券"
          visible={this.state.visible}
          onOk={this.add_coupon}
          onCancel={this.handleCancel}
          okText="提交"
          cancelText="取消"
        >
        
          <Radio.Group defaultValue="SUB" buttonStyle="solid" onChange={this.change_radio} >
              <Radio.Button value="SUB" >满减券</Radio.Button>
              <Radio.Button value="LEARN">体验券</Radio.Button>
          </Radio.Group>
          {this.state.coupon_type=='SUB'?<div>
            <Input placeholder="请输入优惠券名称" style={{ marginTop: '15px' }} value={this.state.coupon_title} onChange={this.change_coupon_title.bind(that)} />
            <Input placeholder="请输入优惠金额" style={{ marginTop: '15px' }} value={this.state.coupon_price} onChange={this.change_coupon_price.bind(that)} />
            <InputNumber min={1} max={10000} placeholder="请输入有效期(天)" style={{ width: '100%',marginTop: '15px' }} value={this.state.coupon_time} onChange={this.change_coupon_time.bind(that)} />
            <InputNumber min={1} max={10000} placeholder="请输入总发放量" style={{ width: '100%',marginTop: '15px' }} value={this.state.coupon_num} onChange={this.change_coupon_num.bind(that)} />
            <Select
                placeholder="请选择优惠券类型"
                onChange={this.change_type.bind(this)}
                value={this.state.course_id}
                style={{ marginTop: '15px' ,width:"100%"}}
              >
                <Option key="全场通用" >全场通用</Option>
                {
                  this.state.course_data.map((item) => {
                    return <Option key={item.title} >{item.title}</Option>
                  })
                }
              </Select>
            <InputNumber placeholder="请输入限制使用金额,0为无限制" min={0} style={{width: '100%', marginTop: '15px' }} value={this.state.limit_price} onChange={this.change_limit_price.bind(that)} />
          </div>:<div>
          <Input placeholder="请输入体验券名称" style={{ marginTop: '15px' }} value={this.state.coupon_title} onChange={this.change_coupon_title.bind(that)} />
          <Input placeholder="请输入体验券实付金额" style={{ marginTop: '15px' }} value={this.state.coupon_price} onChange={this.change_coupon_price.bind(that)} />
          <InputNumber min={1} max={10000} placeholder="请输入总发放量" style={{ width: '100%',marginTop: '15px' }} value={this.state.coupon_num} onChange={this.change_coupon_num.bind(that)} />
           <InputNumber min={1} max={10000} placeholder="请输入有效期(天)" style={{ width: '100%',marginTop: '15px' }} value={this.state.coupon_time} onChange={this.change_coupon_time.bind(that)} />
           <InputNumber min={1} max={10000} placeholder="请输入体验期(天)" style={{ width: '100%',marginTop: '15px' }} value={this.state.learn_time} onChange={this.change_learn_time.bind(that)} />
            <Select
                placeholder="请选择专栏类型"
                onChange={this.change_type.bind(this)}
                value={this.state.course_id}
                style={{ marginTop: '15px' ,width:"100%"}}
              >
                {
                  this.state.course_data.map((item) => {
                    return <Option key={item.title} >{item.title}</Option>
                  })
                }
              </Select>
            </div>}
  
        </Modal>
        {/* 二维码界面 */}
        <Modal
          title="生成领券二维码"
          visible={this.state.qrcode_visible}
          onOk={this.hiden_qrcode}
          onCancel={this.hiden_qrcode}
          okText="确定"
          cancelText="取消"
        >
          <div className="qrcode-window-view">
            <text className="qrcode-font1">用微信扫描下方二维码</text>
            <text className="qrcode-font1" style={{ marginBottom: '10px' }}>即可领取优惠券</text>
            <QRCode value={this.state.qrcode_url} />
          </div>
        </Modal>
        {/* 库存界面 */}
        <Modal
            title="增加库存"
            visible={this.state.remain_num_visible}
            onOk={this.submit_remain_num}
            onCancel={this.hidden_remain_num}
            okText="提交"
            cancelText="取消"
          >
            <InputNumber placeholder="请输入增加的库存个数" value={this.state.remain_num} min={1} max={10000} onChange={that.change_remain_num.bind(this)} style={{ width: '300px' }} />

          </Modal>
      </Card>

    );
  }
}

Coupon_list = Form.create()(Coupon_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Coupon_list)